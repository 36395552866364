export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companymovementtypes",
    name: "companymovementtypes.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/companymovementtypes",
    name: "companymovementtypes.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companymovementtypes/list",
    name: "companymovementtypes.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companymovementtypes/{id}",
    name: "companymovementtypes.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/companymovementtypes/{id}",
    name: "companymovementtypes.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/companymovementtypes/{id}",
    name: "companymovementtypes.destroy",
  },
];
