import type { NavigationGuard, RedirectOption } from "vue-router";
import type {
  Component,
  PathToRegexpOptions,
  RoutePropsFunction,
} from "vue-router/types/router";
import {
  chain,
  concat,
  get,
  has,
  isArray,
  isEmpty,
  join,
  kebabCase,
  map,
  replace,
  sortBy,
  unset,
} from "lodash";
import type { PermissionRole } from "./permissions";

export interface RouteConfigIcon {
  icon: string;
  original: boolean;
}

export interface RouteConfigBreadcrumb {
  label: string;
  parent: string;
}

export interface RouteConfigMeta extends Record<string, any> {
  sortOrder: number;
  module: string;
  title: string;
  group?: string;
  drawer?: boolean;
  main?: boolean;
  iconComponent?: boolean;
  iconProps?: RouteConfigIcon;
  breadcrumb?: RouteConfigBreadcrumb;
  permissions?: PermissionRole[];
}

export interface RouteConfigLocal {
  page?: string;
  path: string;
  name?: string;
  children?: RouteConfigLocal[];
  redirect?: RedirectOption;
  alias?: string | string[];
  meta?: any;
  beforeEnter?: NavigationGuard;
  caseSensitive?: boolean;
  pathToRegexpOptions?: PathToRegexpOptions;
  component?: Component;
  props?: boolean | Record<string, any> | RoutePropsFunction;
}

const hasDefaultChild = (route: RouteConfigLocal) => {
  if (!has(route, "children") || isEmpty(route.children)) {
    return false;
  }

  const obNestRoute = chain(route.children)
    .filter((obRoute: RouteConfigLocal) => obRoute.path == "")
    .first()
    .value();

  return !obNestRoute || !isEmpty(obNestRoute.children)
    ? false
    : has(obNestRoute, "page");
};

/**
 * Map route
 * @author Alvaro Canepa <bfpdevel@gmail.com>
 * @param {RouteConfigLocal} route
 * @param {string} [sModuleName]
 * @param {RouteConfigLocal} [parentRoute]
 * @return {*}
 */
const mapRoute = (
  route: RouteConfigLocal,
  sModuleName?: string,
  parentRoute?: RouteConfigLocal
) => {
  const hasChild = isArray(route.children);
  const hasChildDefault = hasDefaultChild(route);
  // const isMain = get(route, "meta.main", false);
  const sModule = get(route, "meta.module", sModuleName);
  const sComponentPath = `modules/${sModule}/views`;
  const sView = chain(route.page).camelCase().upperFirst().value();
  let sName = get(route, "name");

  // Route is child
  if (parentRoute) {
    // if (!route.path || isEmpty(route.path) || route.path == "") {
    //   route.path = parentRoute.path;
    // }

    // Construct child route name
    const arRouteParts: string[] = [];

    if (parentRoute.name) {
      arRouteParts.push(parentRoute.name);
    } else {
      arRouteParts.push(kebabCase(parentRoute.path));
    }

    if (!sName) {
      sName = kebabCase(route.path);
    }

    arRouteParts.push(sName);
    sName = join(arRouteParts, "-");
  } else if (route.path) {
    if (!sName) {
      sName = kebabCase(route.path);
    }
  }

  if (sName) {
    sName = replace(sName, "-", ".");
  }

  route.name = sName;

  if (hasChild) {
    route.children = map(route.children, (r) => mapRoute(r, sModule, route));
  }

  route.component = () => import(`@/${sComponentPath}/${sView}.vue`);

  if (hasChildDefault) {
    unset(route, "name");
  }

  return route;
};

let baseRoutes: RouteConfigLocal[] = [];

const req = require.context("@/", true, /modules\/([^/]+\/)router\/index\.ts/);
req.keys().forEach((key) => {
  const obModule = req(key);
  baseRoutes = concat(baseRoutes, obModule.default);
});
baseRoutes = sortBy(baseRoutes, (r) => {
  return get(r, "meta.sortOrder", 99);
});

const routes = map(baseRoutes, (r) => mapRoute(r));

export default routes;
