export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentryinvoices",
    name: "accountingentryinvoices.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accountingentryinvoices",
    name: "accountingentryinvoices.store",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accountingentryinvoices/generate",
    name: "accountingentryinvoices.generate",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accountingentryinvoices/remove",
    name: "accountingentryinvoices.remove",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accountingentryinvoices/export",
    name: "accountingentryinvoices.export",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentryinvoices/list",
    name: "accountingentryinvoices.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentryinvoices/{id}",
    name: "accountingentryinvoices.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/accountingentryinvoices/{id}",
    name: "accountingentryinvoices.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/accountingentryinvoices/{id}",
    name: "accountingentryinvoices.destroy",
  },
];
