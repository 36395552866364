export default [
  // - Settings
  {
    uri: "api/v1/gw/settings",
    name: "settings.index",
    methods: ["GET", "HEAD"],
  },
  { uri: "api/v1/gw/settings", name: "settings.store", methods: ["POST"] },
  {
    uri: "api/v1/gw/settings",
    name: "settings.show",
    methods: ["GET", "HEAD"],
  },
];
