import { create, setChildren } from "@/router/utils";
import { cloneDeep, filter } from "lodash";

const obRoute = create("migrations", null, "index", {
  sortOrder: 999,
  iconProps: { icon: "database", outlined: true },
});
const arChildList = filter(obRoute.children, (obRoute) => {
  return obRoute.name !== "view";
});
arChildList.push(
  setChildren("migrations", "view", cloneDeep(obRoute.meta), "view", "")
);
arChildList.push(
  setChildren(
    "migrations",
    "module",
    cloneDeep(obRoute.meta),
    "module_view",
    "module/:id"
  )
);

obRoute.children = arChildList;
export default [obRoute];
