import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";

@Module({
  name: "layout",
  dynamic: true,
  namespaced: true,
  store: store,
})
class Layout extends VuexModule {
  private _layout = "init";

  get layout(): string {
    return this._layout;
  }

  private _reload = false;

  get reload(): boolean {
    return this._reload;
  }

  @Action
  setLayout(layout: string) {
    this.SET_LAYOUT(layout);
  }

  @Action
  setReload(reload: boolean) {
    this.SET_RELOAD(reload);
  }

  @Action
  setReloadOn() {
    this.SET_RELOAD(true);
  }

  @Action
  setReloadOff() {
    this.SET_RELOAD(false);
  }

  @Mutation
  private SET_LAYOUT(name: string) {
    this._layout = name;
  }

  @Mutation
  private SET_RELOAD(reload: boolean) {
    this._reload = reload;
  }
}

export const LayoutModule = getModule(Layout);
