import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import type { TaxTypeData } from "@planetadeleste/vue-mc-gw";
import { TaxTypeCollection } from "@planetadeleste/vue-mc-gw";

export interface TaxTypeState {
  _items: Partial<TaxTypeData>[];
}

@Module({
  name: "TaxType",
  dynamic: true,
  namespaced: true,
  store: store,
  preserveState: localStorage.getItem("vuex") !== null,
})
class TaxType extends VuexModule implements TaxTypeState {
  isLoading = false;
  _items: Partial<TaxTypeData>[] = [];

  get items(): Partial<TaxTypeData>[] {
    return this._items;
  }

  get collection() {
    const obCollection = new TaxTypeCollection();
    obCollection.add(this.items);

    return obCollection;
  }

  get models() {
    return this.collection.getModels();
  }

  @Action
  async load(obFilters: Record<string, any> = {}) {
    // prevent multiple calls
    if (this.isLoading) return;

    this.setLoadingState(true);

    try {
      const obCollection = new TaxTypeCollection();
      await obCollection.filterBy(obFilters).list();
      this.setItemsState(obCollection.getModelList() as TaxTypeData[]);
    } catch (e) {
      console.error("An error occurred:", e);
    }

    this.setLoadingState(false);
  }

  // Updating Loading Fields State
  @Mutation
  setLoadingState(state: boolean) {
    this.isLoading = state;
  }

  @Mutation
  setItemsState(arData: TaxTypeData[]) {
    this._items = arData;
  }
}

export const TaxTypeModule = getModule(TaxType);
