import type { MenuItem } from "@/config/mainMenu";
import { AuthModule } from "@/store/auth";
import type { Route } from "vue-router";
import type VueRouter from "vue-router";
import type { RouteConfigLocal } from "@/router/routes";
import {
  chain,
  filter,
  get,
  isFunction,
  isNil,
  kebabCase,
  map,
  set,
  uniqueId,
  unset,
} from "lodash";
import { routeAccess } from "@/plugins/helpers";
import type { CallbackWithResponse } from "@/types/utils";

export default function (
  arMenuItems: MenuItem[],
  $router: VueRouter,
  cb?: CallbackWithResponse<boolean>
) {
  const user = AuthModule.user;

  const mapRoute = (route: Route | RouteConfigLocal) => {
    if (get(route, "meta.iconComponent")) {
      const obSvgConfig = get(route, "meta.iconProps");
      const sIcon = get(obSvgConfig, "icon", "home");

      if (get(obSvgConfig, "outlined") || sIcon.includes("outline")) {
        set(obSvgConfig, "fill", false);
      }

      set(route, "meta.iconProps", obSvgConfig);
      set(route, "meta.icon", `icon-${sIcon}`);
    }

    return route;
  };

  const getRoute = (sName: string): Route | RouteConfigLocal => {
    return $router.resolve({ name: sName }).route;
  };

  /**
   * Map menu item and it children adding route property
   *
   * @param {MenuItem} obItem
   * @returns {MenuItem}
   */
  const mapItem = (obItem: MenuItem): MenuItem => {
    if (obItem.to) {
      const obRoute = getRoute(obItem.to);
      mapRoute(obRoute);
      set(obItem, "route", obRoute);

      if (obRoute.name) {
        set(obItem, "key", kebabCase(obRoute.name));
      }
    }

    if (obItem.children && obItem.children.length) {
      const arChilds = map(obItem.children, mapItem);
      set(obItem, "children", arChilds);
    }

    if (!obItem.key) {
      set(obItem, "key", uniqueId(kebabCase(obItem.title)));
    }

    return obItem;
  };

  /**
   * Filter menu item by permissions
   *
   * @param {MenuItem} obItem
   * @returns {boolean}
   */
  const filterItem = (obItem: MenuItem): boolean => {
    if (obItem.devel && process.env.NODE_ENV !== "development") {
      return false;
    }

    if (cb && isFunction(cb) && !cb(obItem)) {
      return false;
    }

    if (user.role === "admin" && !obItem.children) {
      return true;
    }

    if (obItem.title === "admin" && !obItem.children) {
      return false;
    }

    if (
      (!obItem.route || isNil(obItem.route)) &&
      (!obItem.children || !obItem.children.length)
    ) {
      return false;
    }

    if (obItem.children && obItem.children.length) {
      const arChilds = filter(obItem.children, filterItem);

      if (!arChilds.length) {
        unset(obItem, "children");
      } else {
        set(obItem, "children", arChilds);
      }
    }

    // Double check on children
    if (
      (!obItem.route || isNil(obItem.route)) &&
      (!obItem.children || !obItem.children.length)
    ) {
      return false;
    }

    return obItem.route ? routeAccess(obItem.route, user) : true;
  };

  const deepClone = (arItems: MenuItem[]) => {
    return map(arItems, (obItem) => {
      const obCloned = { ...obItem };

      if (obCloned.children) {
        set(obCloned, "children", deepClone(obCloned.children));
      }

      return obCloned;
    });
  };

  const setItems: () => MenuItem[] = () => {
    return chain(deepClone(arMenuItems))
      .map(mapItem)
      .filter(filterItem)
      .value();
  };

  return { setItems };
}
