export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/taxtypes",
    name: "taxtypes.index",
  },
  { methods: ["POST"], uri: "api/v1/gw/taxtypes", name: "taxtypes.store" },
  {
    methods: ["POST"],
    uri: "api/v1/gw/taxtypes/reorder",
    name: "taxtypes.reorder",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/taxtypes/list",
    name: "taxtypes.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/taxtypes/{id}",
    name: "taxtypes.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/taxtypes/{id}",
    name: "taxtypes.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/taxtypes/{id}",
    name: "taxtypes.destroy",
  },
];
