<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { union, get } from "lodash";

@Component({})
export default class TBreadCrumbs extends Vue {
  /**
   * Icon separator
   * @type String
   * @default "mdi-chevron-right"
   */
  @Prop({ type: String, default: "mdi-chevron-right" }) readonly icon!: string;

  get items() {
    const pathArray = this.$route.path.split("/");
    pathArray.shift();
    const breadcrumbs = pathArray.reduce(
      (breadcrumbArray: any[], path: string, idx: number) => {
        const obCrumb = get(breadcrumbArray, idx - 1);
        const sTitle = get(this.$route.matched[idx], "meta.title", path);

        breadcrumbArray.push({
          path: path,
          to: obCrumb ? `/${get(obCrumb, "path")}/${path}` : `/${path}`,
          text: this.$t(sTitle),
        });
        return breadcrumbArray;
      },
      []
    );

    const home = { to: "/", text: this.$t("home") };
    return union([home], breadcrumbs);
  }

  get title() {
    return get(this.$route.matched[0], "meta.title", "");
  }
}
</script>

<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <div
        class="tw-font-medium tw-text-xl tw-capitalize"
        v-if="title.length"
        v-text="$t(title)"
      />
      <v-breadcrumbs
        :items="items"
        class="tw-border-0 tw-border-solid tw-border-l tw-border-gray-300 pa-2 pl-4 ml-4"
      >
        <template v-slot:divider>
          <v-icon>{{ icon }}</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
  </v-row>
</template>
