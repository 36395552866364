import { company } from "@/router/permissions";
import { create, setChildren } from "@/router/utils";
import { cloneDeep, filter } from "lodash";

const obRoute = create(
  "accountingentries",
  null,
  "index",
  {
    sortOrder: 520,
    iconProps: { icon: "sliders", original: true },
  },
  company
);

const arChildList = filter(obRoute.children, (obRoute) => {
  return !obRoute.name || !["update", "create"].includes(obRoute.name);
});
arChildList.push(
  setChildren(
    "accountingentries",
    "create",
    cloneDeep(obRoute.meta),
    "form",
    ""
  )
);
arChildList.push(
  setChildren(
    "accountingentries",
    "update",
    cloneDeep(obRoute.meta),
    "form",
    "update/:id"
  )
);
obRoute.children = arChildList;

export default [obRoute];
