import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import type { AccountData } from "@planetadeleste/vue-mc-gw";
import { AccountCollection } from "@planetadeleste/vue-mc-gw";

export interface AccountState {
  _items: Partial<AccountData>[];
}

@Module({
  name: "Account",
  dynamic: true,
  namespaced: true,
  store: store,
  preserveState: localStorage.getItem("vuex") !== null,
})
class Account extends VuexModule implements AccountState {
  public isLoading = false;

  _items: Partial<AccountData>[] = [];

  get items(): Partial<AccountData>[] {
    return this._items;
  }

  get collection() {
    const obCollection = new AccountCollection();
    obCollection.add(this.items);

    return obCollection;
  }

  get models() {
    return this.collection.getModels();
  }

  @Action
  async load(obFilters: Record<string, any> = {}) {
    // prevent multiple calls
    if (this.isLoading) return;

    this.setLoadingState(true);

    try {
      const obCollection = new AccountCollection();
      await obCollection.filterBy(obFilters).list();
      this.setItemsState(obCollection.getModelList() as AccountData[]);
    } catch (e) {
      console.error("An error occurred:", e);
    }

    this.setLoadingState(false);
  }

  // Updating Loading Fields State
  @Mutation
  setLoadingState(state: boolean) {
    this.isLoading = state;
  }

  @Mutation
  setItemsState(arData: AccountData[]) {
    this._items = arData;
  }

  @Mutation
  reset() {
    this._items = [];
  }
}

export const AccountModule = getModule(Account);
