export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/migrationtasks",
    name: "migrationtasks.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/migrationtasks",
    name: "migrationtasks.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/migrationtasks/list",
    name: "migrationtasks.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/migrationtasks/{id}",
    name: "migrationtasks.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/migrationtasks/{id}",
    name: "migrationtasks.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/migrationtasks/{id}",
    name: "migrationtasks.destroy",
  },
];
