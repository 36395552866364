import { limited } from "@/router/permissions";
import { create, setChildren } from "@/router/utils";
import { cloneDeep, set } from "lodash";

const obRoute = create(
  "reports",
  null,
  "index",
  {
    sortOrder: 600,
    iconProps: { icon: "pie-report", original: true },
  },
  limited
);
const arChildList = [
  setChildren("reports", "dash", cloneDeep(obRoute.meta), "dash", ""),
  setChildren(
    "reports",
    "subdaily",
    cloneDeep(obRoute.meta),
    "sub_daily",
    "subdaily"
  ),
  setChildren("reports", "unpaid", cloneDeep(obRoute.meta), "unpaid", "unpaid"),
  setChildren(
    "reports",
    "balance",
    cloneDeep(obRoute.meta),
    "balance",
    "balance"
  ),
  setChildren(
    "reports",
    "receipt",
    cloneDeep(obRoute.meta),
    "receipt",
    "receipt"
  ),
  setChildren("reports", "sales", cloneDeep(obRoute.meta), "sales", "sales"),
  setChildren(
    "reports",
    "accountstatement",
    cloneDeep(obRoute.meta),
    "account_statement",
    "accountstatement"
  ),
];
set(obRoute, "children", arChildList);

export default [obRoute];
