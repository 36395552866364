<template>
  <div class="pde-profile-nav d-flex align-center">
    <p class="ma-0 d-none d-lg-block" v-text="fullName" />
    <v-avatar :size="avatarSize" class="ml-2">
      <img :src="user.avatar" :alt="fullName" v-if="user.avatar" />
      <icon-person-outline size="1.7em" outlined v-else />
    </v-avatar>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { AuthModule } from "@/store/auth";

@Component
export default class ProfileNav extends Vue {
  get user() {
    return AuthModule.user;
  }

  get fullName(): string {
    return this.$_.chain([this.user.name, this.user.last_name])
      .filter((sValue) => !this.$_.isNil(sValue) && !this.$_.isEmpty(sValue))
      .join(" ")
      .value();
  }

  get avatarSize() {
    return this.$vuetify.breakpoint.smAndDown ? 32 : 40;
  }
}
</script>
