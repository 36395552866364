export default [
  {
    methods: ["POST"],
    uri: "api/v1/gw/reports/{type}/{format}",
    name: "reports.get",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/reports/download/{key}/{format}",
    name: "reports.download",
  },
];
