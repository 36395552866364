<template>
  <v-slide-x-transition name="fade" mode="out-in">
    <component :is="layout" />
  </v-slide-x-transition>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { LayoutModule } from "@/store/layout";

import LoginLayout from "@/layout/login.vue";
import InitLayout from "@/layout/init.vue";
import MainLayout from "@/layout/main.vue";

@Component({
  components: {
    LoginLayout,
    InitLayout,
    MainLayout,
  },
})
export default class App extends Vue {
  get layout(): string {
    return `${LayoutModule.layout}-layout`;
  }
}
</script>

<style lang="scss">
@use "src/assets/styles/styles";
</style>
