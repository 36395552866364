<template>
  <form-observer
    :button-options="buttonOptions"
    :title="title"
    :dirty="dirty"
    :check-dirty="checkDirty"
    v-bind="$attrs"
    @cancel="onCancel"
    @save="onSave"
  >
    <!-- Pass on all named slots -->
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>

    <!-- Pass on all scoped slots -->
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps" />
    </template>
  </form-observer>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import obSettings from "@/config/app";
import { assign, get, isBoolean } from "lodash";

@Component
export default class GwFormObserver extends Vue {
  @Prop(String) readonly title!: string;
  @Prop({ type: Boolean, default: undefined }) readonly dirty!: boolean;

  get buttonOptions() {
    const obAttrs = get(this.$attrs, "button-options", {});
    return assign(obSettings.button, obAttrs);
  }

  get checkDirty() {
    return isBoolean(this.dirty);
  }

  onSave() {
    this.$emit("save");
  }

  onCancel() {
    this.$emit("cancel");
  }
}
</script>
