<template>
  <v-row>
    <v-col v-if="!fixed" cols="12">
      <v-switch
        v-model="changePassword"
        :label="$t('change.password')"
      ></v-switch>
    </v-col>
    <v-expand-transition mode="out-in">
      <v-col v-if="changePassword || fixed" cols="12">
        <v-row>
          <v-col cols="12" lg="6">
            <form-field-password
              v-model="sPassword"
              label="password"
              rules="required|max:100|min:8"
              v-bind="$attrs"
              vid="password"
              @input="onChangePassword"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <form-field-password
              v-model="sPasswordConfirm"
              label="password.confirmation"
              rules="required|confirmed:password"
              v-bind="$attrs"
              vid="password_confirmation"
              @input="onChangePassword($event, 'password_confirmation')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { User } from "@planetadeleste/vue-mc-shopaholic";

type PasswordColumn = "password" | "password_confirmation";

@Component
export default class FormFieldPasswords extends Vue {
  @VModel({ type: Object, default: () => new User() }) user!: User;
  @Prop(Boolean) readonly fixed!: boolean;
  changePassword = false;

  sPassword = "";
  sPasswordConfirm = "";

  save(sValue: string) {
    this.$emit("save", sValue);
  }

  onChangePassword(sValue: string, sColumn: PasswordColumn = "password") {
    this.user.set(sColumn, sValue);
  }
}
</script>
