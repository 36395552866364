import Vue from "vue";
import Vuex, { type MutationPayload } from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({ overwrite: false }),
    createMutationsSharer({
      predicate: (mutation: MutationPayload) => {
        const arPredicateList = [
          "app/setCompany",
          "app/setBranch",
          "invoice/_setInvoice",
          "invoice/_setTaxes",
          "invoice/_setRoutedMovementTypeCode",
          "invoice/_setRoutedTypeCode",
          "product/setItems",
        ];

        return arPredicateList.includes(mutation.type);
      },
    }),
  ],
});
