import type { AbilityClass } from "@casl/ability";
import { AbilityBuilder, Ability } from "@casl/ability";
import type { ProfileData } from "@planetadeleste/vue-mc-shopaholic";

type Actions = "manage" | "create" | "read" | "update" | "delete";
export type Subjects =
  | "Company"
  | "Product"
  | "Brand"
  | "Category"
  | "Customer"
  | "Contract"
  | "ContractCategory"
  | "Invoice"
  | "PaymentTerm"
  | "PaymentMethod"
  | "AccountConfig"
  | "PriceList"
  | "VendorCollector"
  | "Warehouse"
  | "User"
  | "global"
  | "all";

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export const defineRulesFor = (obUser: ProfileData) => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);
  const arAllActions: Actions[] = ["read", "update", "create", "delete"];

  switch (obUser.role) {
    case "admin":
      can("manage", "all");
      break;

    case "company":
    case "limited":
      can(["read", "update"], "Company");
      can(arAllActions, "Product");
      can(arAllActions, "Brand");
      can(arAllActions, "Category");
      can(arAllActions, "Customer");
      can(arAllActions, "Contract");
      can(arAllActions, "ContractCategory");
      can(arAllActions, "Invoice");
      can(arAllActions, "PaymentTerm");
      can(arAllActions, "AccountConfig");
      can(arAllActions, "PriceList");
      can(arAllActions, "VendorCollector");
      can(arAllActions, "Warehouse");
      break;

    default:
      cannot(arAllActions, "all");
      break;
  }

  return rules;
};

export const buildAbilityFor = (obUser: ProfileData) => {
  return new AppAbility(defineRulesFor(obUser), {
    // detectSubjectType: (object) => object?.constructor.name,
  });
};
