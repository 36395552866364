<template>
  <v-footer :fixed="fixed" app height="auto">
    <v-row class="justify-center">
      <v-col class="text-center">
        <span>
          &copy; {{ copyright }}
          <span class="font-weight-bold" v-text="title" />
        </span>
        <!-- <span class="body-2 float-right tw-text-gray-500" v-text="appVersion" /> -->
      </v-col>
    </v-row>
  </v-footer>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
// import { AppModule } from "@/store/app";

@Component
export default class MainFooter extends Vue {
  @Prop({ default: false, type: Boolean }) readonly fixed!: boolean;
  @Prop({ type: String }) readonly title!: string;

  /**
   * Display copyright year
   */
  get copyright() {
    return this.$dayjs().format("YYYY");
  }

  // get appVersion() {
  //   return AppModule.getAppVersion;
  // }
}
</script>
