import Vue from "vue";
// import { i18n } from "@/plugins/i18n";
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import * as rules from "vee-validate/dist/rules";
import useRUT from "@/composables/rut";
import useCI from "@/composables/ci";

localize("es", es);

const arRules = [
  "confirmed",
  "double",
  "email",
  "excluded",
  "is",
  "is_not",
  "max",
  "max_value",
  "min",
  "min_value",
  "numeric",
  "required",
  "required_if",
];

for (const [rule, validation] of Object.entries(rules)) {
  if (arRules.includes(rule)) {
    extend(rule, { ...validation });
  }
}

// RUT validation
extend("rut", {
  validate: (sValue: string) => useRUT(sValue).validate(),
  message: "El documento RUT no es válido",
});

// CI validation
extend("ci", {
  validate: (sValue: string) => useCI(sValue).validate(),
  message: "El número de documento no es válido",
});

// Multiple Emails
extend("email_multiple", {
  validate: (sValue: string) => {
    const reg =
      /^([A-Za-z0-9.-_]+@[A-Za-z0-9.-_]+\.[A-Za-z]+)(,[A-Za-z0-9.-_]+@[A-Za-z0-9.-_]+\.[A-Za-z]+)*$/;
    return reg.test(sValue);
  },
  message: "Formato de correo/s invalido/s. Recuerde separarlos por coma.",
});

// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
