import { cloneDeep, set } from "lodash";
import routeOffer from "./offer";
import { limited } from "@/router/permissions";
import { create, setChildren } from "@/router/utils";

const obRoute = create(
  "products",
  null,
  "index",
  {
    sortOrder: 300,
    iconProps: { icon: "package", original: true },
  },
  limited,
  false
);

const arChildList = [
  setChildren("products", "list", cloneDeep(obRoute.meta), "list", ""),
  setChildren(
    "products",
    "update",
    cloneDeep(obRoute.meta),
    "list",
    "update/:id",
    [cloneDeep(routeOffer)]
  ),
  setChildren("products", "view", cloneDeep(obRoute.meta), "list", "view/:id", [
    cloneDeep(routeOffer),
  ]),
  setChildren("products", "create", cloneDeep(obRoute.meta), "list", "create", [
    cloneDeep(routeOffer),
  ]),
];
set(obRoute, "children", arChildList);

export default [obRoute];
