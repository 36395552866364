<template>
  <form-field-text
    v-model="sValue"
    :label="label"
    :mask="mask"
    :placeholder="placeholder"
    @save="save"
    v-bind="$attrs"
  >
    <template #append v-if="icon">
      <component :is="`icon-${icon}`" size="1.8em" />
    </template>
  </form-field-text>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import FormFieldText from "@/components/form/fields/Text.vue";

@Component({
  components: { FormFieldText },
})
export default class FormFieldDate extends Vue {
  @Prop(String) readonly value!: string;
  @Prop({ type: String, default: "calendar-outline" }) readonly icon!: string;
  @Prop({ type: String, default: "date" }) readonly label!: string;
  @Prop({ type: String, default: "## / ## / ####" }) readonly mask!: string;
  @Prop({ type: String, default: "DD / MM / YYYY" })
  readonly placeholder!: string;

  save(sValue: string) {
    this.$emit("save", sValue);
  }

  get sValue() {
    return this.value;
  }

  set sValue(sValue: string) {
    this.$emit("input", sValue.replace(/\s+/g, ""));
  }
}
</script>
