export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/modules",
    name: "modules.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/modules",
    name: "modules.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/modules/list",
    name: "modules.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/modules/{id}",
    name: "modules.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/modules/{id}",
    name: "modules.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/modules/{id}",
    name: "modules.destroy",
  },
];
