export default [
  { methods: ["GET", "HEAD"], uri: "api/v1/gw/banks", name: "banks.index" },
  { methods: ["POST"], uri: "api/v1/gw/banks", name: "banks.store" },
  { methods: ["GET", "HEAD"], uri: "api/v1/gw/banks/list", name: "banks.list" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/banks/{id}",
    name: "banks.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/banks/{id}",
    name: "banks.update",
  },
  { methods: ["DELETE"], uri: "api/v1/gw/banks/{id}", name: "banks.destroy" },
];
