export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/migrationmonitors",
    name: "migrationmonitors.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/migrationmonitors",
    name: "migrationmonitors.store",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/migrationmonitors/dispatch/{id}",
    name: "migrationmonitors.dispatch",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/migrationmonitors/list",
    name: "migrationmonitors.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/migrationmonitors/{id}",
    name: "migrationmonitors.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/migrationmonitors/{id}",
    name: "migrationmonitors.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/migrationmonitors/{id}",
    name: "migrationmonitors.destroy",
  },
];
