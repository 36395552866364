<template>
  <v-list-item-content v-if="item && menu">
    <v-list-item-title v-text="name" />
    <v-list-item-subtitle v-text="info" />
  </v-list-item-content>
  <v-list-item v-else-if="item">
    <v-list-item-content>
      <v-list-item-title v-text="name" />
      <v-list-item-subtitle v-text="info" />
    </v-list-item-content>
  </v-list-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { TaxTypeData } from "@planetadeleste/vue-mc-gw";

@Component
export default class TaxTypePreview extends Vue {
  @Prop(Object) readonly item!: TaxTypeData;
  @Prop(Boolean) readonly menu!: boolean;

  get name() {
    return this.item?.name;
  }

  get info() {
    return this.item
      ? `${this.item.description} (IndFac: ${this.item.code})`
      : undefined;
  }
}
</script>
