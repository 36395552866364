<template>
  <v-row>
    <v-col class="px-8" cols="12" lg="6">
      <v-subheader v-text="$t('title.settings.prices')" />
      <v-row>
        <!--
        <v-col cols="12">
          <v-switch
            v-model="showPriceWithTax"
            :label="$t('show.prices.with.tax')"
          />
        </v-col>
-->
        <v-col cols="12">
          <v-switch
            v-model="obSettings.discounts_per_line"
            :label="$t('discounts.per.line')"
          />
        </v-col>

        <v-col v-if="obSettings.discounts_per_line" cols="12">
          <v-subheader>{{ $t("max.inline.discounts") }}</v-subheader>
          <v-radio-group
            v-model="obSettings.max_inline_discounts"
            class="mt-0"
            row
          >
            <template v-for="n in gwMaxGlobalDiscounts">
              <v-radio
                :key="`company-settings-maxid-${n}`"
                :label="$_.toString(n)"
                :value="n"
              />
            </template>
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <v-switch
            v-model="hasGlobalDiscounts"
            :label="$t('has.global.discounts')"
          ></v-switch>
        </v-col>

        <v-col v-if="hasGlobalDiscounts" cols="12">
          <v-subheader>{{ $t("max.global.discounts") }}</v-subheader>
          <v-radio-group
            v-model="obSettings.max_global_discounts"
            class="mt-0"
            row
          >
            <template v-for="n in gwMaxGlobalDiscounts">
              <v-radio
                :key="`company-settings-maxgd-${n}`"
                :label="$_.toString(n)"
                :value="n"
              />
            </template>
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <v-divider />
          <v-subheader>{{ $t("other.settings") }}</v-subheader>
        </v-col>

        <v-col cols="12">
          <currency-select
            v-model="defaultCurrency"
            :hint="$t('company.settings.default.currency.info')"
            :company-only="isActiveCompany"
            persistent-hint
          />
        </v-col>
      </v-row>
    </v-col>

    <v-divider vertical />

    <v-col class="px-8" cols="12" lg="6">
      <v-subheader v-text="$t('title.settings.print')" />

      <v-row>
        <v-col cols="12" lg="6">
          <v-switch
            v-model="obSettings.print_after_billing"
            :label="$t('print.after.billing')"
          ></v-switch>
        </v-col>

        <v-col cols="12" lg="6">
          <v-switch
            v-model="obSettings.print_payment_conditions"
            :label="$t('print.payment.conditions')"
          ></v-switch>
        </v-col>
      </v-row>

      <v-divider />
      <v-subheader v-text="$t('title.settings.addenda')" />

      <v-row>
        <v-col cols="12" lg="6">
          <v-switch
            v-model="obSettings.addenda_in_own_page"
            :label="$t('addenda.in.own.page')"
          ></v-switch>
        </v-col>

        <v-col cols="12" lg="6">
          <v-switch
            v-model="obSettings.addenda_only_for_credits"
            :label="$t('addenda.only.for.credits')"
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="sAddenda"
            :label="$t('addenda')"
            dense
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
    </v-col>

    <slot />
  </v-row>
</template>

<script lang="ts">
import type { CompanySettingsData } from "@planetadeleste/vue-mc-gw";
import { CompanySettings } from "@planetadeleste/vue-mc-gw";
import { Component, VModel, Vue, Watch } from "vue-property-decorator";
import type { DebounceFunction } from "@/plugins/helpers";
import { ConfigModule } from "@/store/config";

import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import TaxTypesSelect from "@/modules/taxtypes/components/TaxTypesSelect.vue";
import { AuthModule } from "@/store/auth";
import { debounce, set } from "lodash";
import { number } from "mathjs";
import { AppModule } from "@/store/app";

@Component({
  components: { CurrencySelect, TaxTypesSelect },
})
export default class SettingsForm extends Vue {
  @VModel({ type: Object, default: () => new CompanySettings() })
  obSettings!: CompanySettings;

  fnDebounceAddenda!: DebounceFunction;
  sAddenda = "";

  get activeCompany() {
    return AppModule.company;
  }

  get gwSettings() {
    return ConfigModule.settings;
  }

  get gwMaxGlobalDiscounts() {
    return number(this.gwSettings.get("max_discounts", 0));
  }

  get hasGlobalDiscounts() {
    return this.iMaxGlobalDiscounts >= 1;
  }

  set hasGlobalDiscounts(bVal: boolean) {
    const sVal = bVal ? 1 : null;
    if (!this.obSettings || (this.iMaxGlobalDiscounts && sVal)) {
      return;
    }

    this.obSettings.set("max_global_discounts", sVal);
  }

  get iMaxGlobalDiscounts() {
    return this.obSettings
      ? number(this.obSettings.get("max_global_discounts"))
      : 0;
  }

  get defaultCurrency() {
    return this.obSettings.get("currency_id");
  }

  set defaultCurrency(sVal: string | number) {
    this.obSettings.set("currency_id", sVal);
  }

  get showPriceWithTax(): boolean {
    return !this.obSettings.get("prices_without_tax");
  }

  set showPriceWithTax(sValue: boolean) {
    this.obSettings.set("prices_without_tax", !sValue);
  }

  get isAdmin() {
    return AuthModule.isAdmin;
  }

  get isActiveCompany(): boolean {
    return (
      this.activeCompany && this.activeCompany.id === this.obSettings.company_id
    );
  }

  @Watch("sAddenda")
  onChangeAddenda() {
    this.fnDebounceAddenda();
  }

  mounted() {
    this.$nextTick(() => {
      this.sAddenda = this.obSettings ? this.obSettings.get("addenda") : "";
      this.obSettings.on("change", (obData) => {
        const obSettingsData = obData.target
          .attributes as Partial<CompanySettingsData>;
        set(obSettingsData, obData.attribute, obData.value);

        this.$emit("input", new CompanySettings(obSettingsData));
      });
    });
  }

  created() {
    this.fnDebounceAddenda = debounce(() => {
      this.obSettings.set("addenda", this.sAddenda);
    }, 2000);
  }
}
</script>
