<template>
  <v-app class="pde-app">
    <v-slide-y-transition mode="out-in" name="fade">
      <router-view />
    </v-slide-y-transition>
    <loading />
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/common/loading/index.vue";

@Component({
  components: { Loading },
})
export default class LoginLayout extends Vue {}
</script>
