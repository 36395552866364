<template>
  <ValidationProvider
    :name="$t(label)"
    :rules="rules"
    :vid="vid"
    :ref="refName"
    slim
  >
    <v-checkbox class="xl-checkbox" v-model="bAgree">
      <template #label><slot name="label" /></template>
    </v-checkbox>
  </ValidationProvider>
</template>
<script lang="ts">
import { Vue, Component, Prop, VModel } from "vue-property-decorator";

@Component
export default class FormFieldAgree extends Vue {
  @VModel({ type: Boolean }) bAgree!: boolean;
  @Prop({ type: String, default: "agree" }) readonly label!: string;
  @Prop({ type: String, default: "agree" }) readonly vid!: string;
  @Prop({ type: [String, Object], default: "required" })
  readonly rules!: string;

  save(sValue: string) {
    this.$emit("save", sValue);
  }

  get refName() {
    return this.$_.camelCase(`field-${this.vid}`);
  }
}
</script>
