<template>
  <v-menu v-model="menu" bottom left offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon v-bind="attrs" v-on="on">
        <icon-ellipsis-vertical-sharp />
      </v-btn>
    </template>

    <v-list>
      <slot name="append-items" :disabled="disabled" :close="closeMenu" />

      <v-list-item
        v-if="!!obEditRoute"
        :disabled="disabled"
        :class="{ 'primary--text': !disabled }"
        :to="obEditRoute"
      >
        <v-list-item-icon>
          <icon-edit />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t('edit')" />
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="!cannotDelete"
        :disabled="disabled"
        :class="{ 'error--text': !disabled }"
        @click="onDelete"
      >
        <v-list-item-icon>
          <icon-trash />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t('remove')" />
        </v-list-item-content>
      </v-list-item>

      <slot name="prepend-items" :disabled="disabled" :close="closeMenu" />
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ModuleActions extends Vue {
  @Prop([String, Number]) readonly value!: any;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly cannotEdit!: boolean;
  @Prop(Boolean) readonly cannotDelete!: boolean;
  @Prop(String) readonly baseRouteName!: string;
  @Prop({ type: String, default: "update" }) readonly updateRoute!: string;
  @Prop({ type: String, default: "id" }) readonly updateKey!: string;

  menu = false;

  closeMenu() {
    this.menu = false;
  }

  onDelete() {
    this.$emit("delete", this.value);
    this.closeMenu();
  }

  get sBaseRoute() {
    if (this.baseRouteName) {
      return this.baseRouteName;
    }

    return this.$route.name && this.$route.name.includes(".")
      ? this.$route.name.split(".").shift()
      : this.$route.name;
  }

  get obEditRoute() {
    if (!this.value || !this.sBaseRoute || this.cannotEdit) {
      return null;
    }

    return {
      name: `${this.sBaseRoute}.${this.updateRoute}`,
      params: { [this.updateKey]: this.value },
    };
  }
}
</script>
