import { Company, type CurrencyCompanyPivot } from "@planetadeleste/vue-mc-gw";
import type { CompanySettings, TaxTypeData } from "@planetadeleste/vue-mc-gw";
import type { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import { isNumber } from "lodash";
import type { CurrencyWithPivotData } from "@/types/utils";

export class CompanyHelper {
  private readonly _company: Company;
  private readonly _companyId: number;

  constructor(obCompany: Company | number) {
    if (isNumber(obCompany)) {
      this._companyId = obCompany;
      this._company = new Company({ id: obCompany });
    } else {
      this._company = obCompany;
      this._companyId = obCompany.id;
    }
  }

  get company(): Company {
    return this._company;
  }

  get companyId(): number {
    return this._companyId;
  }

  get settings(): CompanySettings {
    return this._company.get("settings") as CompanySettings;
  }

  async saveTaxTypes(arIdList: number[]): Promise<TaxTypeData[] | undefined> {
    const obResponse = await this.company
      .createRequest(this.getConfig(arIdList))
      .send();

    return obResponse.response ? obResponse.response.data : undefined;
  }

  async loadCurrencies(): Promise<CurrencyWithPivotData[]> {
    const obResponse = await this.company.getCurrencies();

    return obResponse.response ? obResponse.response.data.data : [];
  }

  async removeCurrency(iCurrencyId: number): Promise<CurrencyWithPivotData[]> {
    const obResponse = await this.company.currencyRemove(iCurrencyId);

    return obResponse.response ? obResponse.response.data.data : [];
  }

  async addUpdateCurrency(
    obData: CurrencyCompanyPivot
  ): Promise<CurrencyWithPivotData[]> {
    const obResponse = await this.company.addUpdateCurrency(obData);

    return obResponse.response ? obResponse.response.data.data : [];
  }

  async showBasic(): Promise<Company | undefined> {
    const obConfigData = {
      url: route("companies.show.basic", { id: this.companyId }),
      method: "GET",
    };
    const obResponse = await this.company.createRequest(obConfigData).send();

    return obResponse.response ? obResponse.response.data.data : undefined;
  }

  private getConfig(arIdList: number[]): AxiosRequestConfig {
    const obData = {
      company_id: this.company.id,
      taxtype_id_list: arIdList,
    };

    return {
      url: route("companies.taxtypes.store", obData),
      method: "POST",
      data: obData,
    };
  }
}

export default function useCompanyHelper(obCompany: Company | number) {
  return new CompanyHelper(obCompany);
}
