<template>
  <div class="text-body-2" v-text="$t(label)" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class FormFieldLabel extends Vue {
  @Prop(String) readonly label!: string;
}
</script>
