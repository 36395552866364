<template>
  <div v-if="arMessages.length" v-frag>
    <v-list-item v-for="sMessage in arMessages" :key="`ib-${sMessage}`" dense>
      <v-list-item-content>
        <v-list-item-title v-text="sMessage" />
        <v-list-item-subtitle v-if="subtitle || hasSubtitle">
          <slot name="subtitle">{{ subtitle }}</slot>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-icon v-if="!hideProgress || hasIcon">
        <slot name="icon" />

        <v-progress-circular
          v-if="!hideProgress"
          :indeterminate="bIndeterminate"
          :value="progress"
          color="primary"
          size="16"
          width="2"
        />
      </v-list-item-icon>
    </v-list-item>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { castArray, filter, get, isNil, isUndefined } from "lodash";

@Component
export default class ItemBroadcast extends Vue {
  @Prop([String, Array]) readonly message!: string | string[];
  @Prop(String) readonly subtitle!: string;
  @Prop(Boolean) readonly hideProgress!: boolean;
  @Prop(Number) readonly progress!: number;

  get hasSubtitle() {
    return get(this.$slots, "subtitle");
  }

  get hasIcon() {
    return get(this.$slots, "icon");
  }

  get bIndeterminate() {
    return isUndefined(this.progress);
  }

  get arMessages() {
    return filter(castArray(this.message), (sItem) => !isNil(sItem));
  }
}
</script>

<style scoped></style>
