import { create } from "@/router/utils";
import { admin } from "@/router/permissions";

const obRoute = create(
  "settings",
  null,
  "index",
  {
    sortOrder: 780,
    iconProps: { icon: "settings", original: true },
  },
  admin,
  false
);

export default [obRoute];
