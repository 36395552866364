import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import {
  Category,
  CategoryCollection,
  type CategoryData,
  Currency,
  CurrencyCollection,
  type CurrencyData,
  Group,
  GroupCollection,
  type GroupData,
} from "@planetadeleste/vue-mc-shopaholic";
import {
  Bank,
  BankCollection,
  type BankData,
  Branch,
  type BranchData,
  Company,
  type CompanyData,
  CompanyMovementType,
  CompanyMovementTypeCollection,
  type CompanyMovementTypeData,
  type CompanySettingsData,
} from "@planetadeleste/vue-mc-gw";
import {
  defaults,
  find,
  get,
  has,
  isEmpty,
  isNil,
  isString,
  map,
  set,
  toString,
} from "lodash";
import { EventBus } from "@/services/event-bus";
import { eqJson } from "@/plugins/helpers";
import { cleanCache } from "@/plugins/axios";
import { ProductModule } from "@/store/product";
import { ConfigModule } from "@/store/config";
import { AccountModule } from "@/store/account";

type FilterParam = {
  model: string;
  value: Record<string, any>;
  key?: string;
};

type FilterStorageValue = Record<string, Record<string, any>>;

@Module({
  dynamic: true,
  store,
  name: "app",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") !== null,
})
class App extends VuexModule {
  private _companyMovementTypeConfig: CompanyMovementTypeData[] = [];

  get companyMovementTypeConfig() {
    return new CompanyMovementTypeCollection(
      this._companyMovementTypeConfig.map(
        (obData) => new CompanyMovementType(obData)
      )
    );
  }

  private _categories: CategoryData[] = [];

  get categories() {
    return new CategoryCollection(
      this._categories.map((obData) => new Category(obData))
    );
  }

  private _groups: GroupData[] = [];

  get groups() {
    return new GroupCollection(this._groups.map((obData) => new Group(obData)));
  }

  private _company: Partial<CompanyData> = {};

  get company(): Company {
    return new Company(this._company);
  }

  private _branch: Partial<BranchData> = {};

  get branch() {
    return new Branch(this._branch);
  }

  private _filters: Partial<FilterStorageValue> = {};

  get filters(): Partial<FilterStorageValue> {
    return this._filters;
  }

  private _currencies: CurrencyData[] = [];

  get currencies() {
    return new CurrencyCollection(
      this._currencies.map((obData) => new Currency(obData))
    );
  }

  private _banks: BankData[] = [];

  get banks() {
    return new BankCollection(this._banks.map((obData) => new Bank(obData)));
  }

  get companyId(): number | undefined {
    return this._company?.id;
  }

  get companySettings(): CompanySettingsData | undefined {
    return this._company?.settings as CompanySettingsData | undefined;
  }

  get companyTaxTypeIdList(): number[] | undefined {
    return this.companySettings?.tax_types;
  }

  get roles(): string[] {
    return map(this._groups, "code");
  }

  get defaultBranch() {
    if (!this.company || !this.company.branches) {
      return undefined;
    }

    if (this.company.branches.length === 1) {
      return this.branch;
    }

    const obBranchData = find(this.company.branches, { is_default: true });
    return obBranchData ? new Branch(obBranchData) : this.branch;
  }

  get defaultCurrency() {
    return this.currencies.find({ is_default: true });
  }

  @Mutation
  setCompanyMovementTypeConfig(value: CompanyMovementTypeData[]) {
    this._companyMovementTypeConfig = value;
  }

  @Mutation
  setBanks(value: BankData[]) {
    this._banks = value;
  }

  @Mutation
  setCurrencies(value: CurrencyData[]) {
    this._currencies = value;
  }

  @Mutation
  setCategories(value: CategoryData[]) {
    this._categories = value;
  }

  @Mutation
  setGroups(value: GroupData[]) {
    this._groups = value;
  }

  @Mutation
  setCompany(obCompanyData?: CompanyData) {
    const obData =
      obCompanyData instanceof Company
        ? obCompanyData.attributes
        : obCompanyData;

    localStorage.removeItem("cid");
    this._company = defaults({}, obData) as Partial<CompanyData>;

    if (this._company.id) {
      const sCompanyID = toString(this._company.id);
      localStorage.setItem("cid", sCompanyID);
    }
  }

  @Mutation
  setBranch(iBranchID?: number) {
    localStorage.removeItem("cbid");
    const arBranches = this._company.branches;
    if (isNil(arBranches) || isEmpty(arBranches)) return;

    const obBranchData = iBranchID
      ? find(arBranches, { id: iBranchID })
      : find(arBranches, { is_default: true });
    if (!obBranchData) return;

    this._branch = obBranchData;
    localStorage.setItem("cbid", toString(this._branch.id));

    const user = localStorage.getItem("user") || "{}";
    const _user = JSON.parse(user);
    set(_user, "default_branch", iBranchID);
    localStorage.setItem("user", JSON.stringify(_user));
    EventBus.emit("app.branch.selected", iBranchID);
  }

  @Action
  async loadCategories() {
    const obCollection = new CategoryCollection();
    await obCollection.tree();
    this.setCategories(obCollection.getModelList() as CategoryData[]);
  }

  @Action
  async loadGroups() {
    const obCollection = new GroupCollection();
    await obCollection.list();
    this.setGroups(obCollection.getModelList() as GroupData[]);
  }

  @Action
  async pickCompany(obCompany: CompanyData) {
    const oldId = this._company?.id;
    const newId = obCompany.id;

    this.setCompany(obCompany);
    this.setBranch();
    cleanCache();
    await this.loadCompanyMovementTypeConfig();
    await this.loadCategories();

    ProductModule.reset();
    ConfigModule.reset();
    AccountModule.reset();

    EventBus.emit("app.company.selected", [newId as number, oldId]);
  }

  @Action
  unsetCompany() {
    this.setCompany();
    this.setBranch();
  }

  @Action
  pickBranch(iBranchID?: number) {
    this.setBranch(iBranchID);
  }

  @Action
  async loadCurrencies() {
    const obCollection = new CurrencyCollection();
    await obCollection.filterBy({ active: 1 }).list();
    this.setCurrencies(obCollection.getModelList() as CurrencyData[]);
  }

  @Action
  async loadCompanyMovementTypeConfig() {
    const obCollection = new CompanyMovementTypeCollection();
    await obCollection.list();
    this.setCompanyMovementTypeConfig(
      obCollection.getModelList() as CompanyMovementTypeData[]
    );
  }

  @Mutation
  setFilter(obFilterData: FilterParam) {
    // Check for changes
    const obFilters = { ...this._filters };
    const obModelFilters = get(obFilters, obFilterData.model);

    if (obModelFilters && eqJson(obModelFilters, obFilterData.value)) {
      return;
    }

    set(obFilters, obFilterData.model, obFilterData.value);
    this._filters = obFilters;

    EventBus.emit("filters.change", obFilterData.model);
  }

  @Action({ commit: "setFilter" })
  addFilter(obFilterData: FilterParam) {
    const sContainer = obFilterData.model;
    const sValue = obFilterData.value as Record<string, any>;
    const sKey = obFilterData.key;
    let obFilters = has(this._filters, sContainer)
      ? get(this._filters, sContainer, {})
      : {};

    if (sValue && isString(sKey)) {
      set(obFilters, sKey, sValue);
    } else {
      obFilters = sValue;
    }

    return {
      model: sContainer,
      value: obFilters,
    };
  }

  @Action
  async loadBanks() {
    const obCollection = new BankCollection();
    await obCollection.filterBy({ active: 1 }).list();
    this.setBanks(obCollection.getModelList() as BankData[]);
  }
}

export const AppModule = getModule(App);
