<template>
  <nav-item :item="item">
    <template #action>
      <v-btn color="primary" text icon @click.prevent.stop="onLogout">
        <v-icon>mdi-power</v-icon>
      </v-btn>
    </template>
  </nav-item>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import type { MenuItem } from "@/config/mainMenu";
import NavItem from "@/layout/components/main/drawer/NavItem.vue";
import AuthMixin from "@/mixins/AuthMixin";

@Component({
  components: { NavItem },
})
export default class AccountMenuItem extends Mixins(AuthMixin) {
  @Prop(Object) readonly item!: MenuItem;

  async onLogout() {
    await this.logOut();
    this.reload();
  }

  reload() {
    return (window.location.href = "/");
  }
}
</script>
