import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import type { InvoiceTypeData } from "@planetadeleste/vue-mc-gw";
import {
  InvoiceType as InvoiceTypeModel,
  InvoiceTypeCollection,
} from "@planetadeleste/vue-mc-gw";

export interface InvoiceTypeState {
  _items: Partial<InvoiceTypeData>[];
}

@Module({
  name: "InvoiceType",
  dynamic: true,
  namespaced: true,
  store: store,
  preserveState: localStorage.getItem("vuex") !== null,
})
class InvoiceType extends VuexModule implements InvoiceTypeState {
  isLoading = false;
  _items: InvoiceTypeData[] = [];

  get items(): InvoiceTypeData[] {
    return this._items;
  }

  get collection() {
    return new InvoiceTypeCollection(
      this._items.map((obItem) => new InvoiceTypeModel(obItem))
    );
  }

  get models() {
    return this.collection.getModels();
  }

  @Action
  async load(obFilters: Record<string, any> = {}) {
    // prevent multiple calls
    if (this.isLoading) return;

    this.setLoadingState(true);

    try {
      const obCollection = new InvoiceTypeCollection();
      await obCollection.filterBy(obFilters).list();
      this.setItemsState(obCollection.getModelList() as InvoiceTypeData[]);
    } catch (e) {
      console.error("An error occurred:", e);
    }

    this.setLoadingState(false);
  }

  // Updating Loading Fields State
  @Mutation
  setLoadingState(state: boolean) {
    this.isLoading = state;
  }

  @Mutation
  setItemsState(arData: InvoiceTypeData[]) {
    this._items = arData;
  }
}

export const InvoiceTypeModule = getModule(InvoiceType);
