import store from "@/store";
import { assign, isNil, unset } from "lodash";
import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";

@Module({ dynamic: true, store, name: "gwcache", namespaced: true })
export default class GwCache extends VuexModule {
  private _obData: Record<string, any> = {};

  get cache() {
    return this._obData;
  }

  @Action
  set(obData: Record<string, any>) {
    if (isNil(obData)) {
      return;
    }

    assign(this._obData, obData);
  }

  @Action
  forget(sKey: string) {
    unset(this._obData, sKey);
  }
}

export const GwCacheModule = getModule(GwCache);
