import { isEmpty } from "lodash";

export const toNumeric = (sValue: string): string => {
  if (isEmpty(sValue)) {
    return sValue;
  }

  const arValue = sValue.match(/\d/g);
  if (arValue?.length) {
    return arValue.join("");
  }

  return sValue;
};
