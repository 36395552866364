import Vue from "vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localizedFormat from "dayjs/plugin/localizedFormat";

import "dayjs/locale/es";

dayjs.extend(duration);
dayjs.extend(localeData);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);

dayjs.locale("es");
dayjs.localeData();

Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get() {
      return dayjs;
    },
  },
  $moment: {
    get() {
      return dayjs;
    },
  },
});
