export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/warehouses",
    name: "warehouses.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/warehouses",
    name: "warehouses.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/warehouses/list",
    name: "warehouses.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/warehouses/{id}",
    name: "warehouses.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/warehouses/{id}",
    name: "warehouses.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/warehouses/{id}",
    name: "warehouses.destroy",
  },
];
