export default [
  { methods: ["GET", "HEAD"], uri: "api/v1/gw/labels", name: "labels.index" },
  { methods: ["POST"], uri: "api/v1/gw/labels", name: "labels.store" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/labels/list",
    name: "labels.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/labels/fields",
    name: "labels.fields",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/labels/{id}",
    name: "labels.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/labels/{id}",
    name: "labels.update",
  },
  { methods: ["DELETE"], uri: "api/v1/gw/labels/{id}", name: "labels.destroy" },
];
