export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/reportjobs",
    name: "reportjobs.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/reportjobs",
    name: "reportjobs.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/reportjobs/list",
    name: "reportjobs.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/reportjobs/{id}",
    name: "reportjobs.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/reportjobs/{id}",
    name: "reportjobs.update",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/reportjobs/download/{id}/{format?}",
    name: "reportjobs.download",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/reportjobs/cancel/{id}",
    name: "reportjobs.cancel",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/reportjobs/{id}",
    name: "reportjobs.destroy",
  },
];
