export default [
  // CUSTOMERS
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/customers",
    name: "customers.index",
  },
  { methods: ["POST"], uri: "api/v1/gw/customers", name: "customers.store" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/customers/list",
    name: "customers.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/customers/{id}",
    name: "customers.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/customers/{id}",
    name: "customers.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/customers/{id}",
    name: "customers.destroy",
  },

  // GENERATE FROM RUT
  {
    methods: ["POST"],
    uri: "api/v1/gw/customers/generate/{id}",
    name: "customers.generate",
  },

  // CURRENCIES
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/customers/{id}/currencies",
    name: "customers.currency.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/customers/{id}/currency",
    name: "customers.currency.store",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/customers/{id}/currency",
    name: "customers.currency.destroy",
  },

  // PAYMENT TERMS
  {
    methods: ["POST"],
    uri: "api/v1/gw/customers/{id}/paymentterm",
    name: "customers.paymentterm.store",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/customers/{id}/paymentterm",
    name: "customers.paymentterm.destroy",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/customers/{id}/paymentterms",
    name: "customers.paymentterm.index",
  },
];
