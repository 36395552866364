import { GwCacheModule } from "@/store/cache";
import { get, isObjectLike, isString } from "lodash";
import Vue from "vue";
import type { Result } from "vue-mc";
import type { Ref } from "@/types/utils";

export type IDListKey =
  | (string & "nie")
  | "rut"
  | "ci"
  | "other"
  | "passport"
  | "dni"
  | "nife";
export type CountryCode = string & ("AR" | "BR" | "CL" | "PY" | "UY");
export type IDList = Record<IDListKey, string>;
export type SellClause = string &
  (
    | "EXW"
    | "FAS"
    | "FOB"
    | "FCA"
    | "CFR"
    | "CIF"
    | "CPT"
    | "CIP"
    | "DAT"
    | "DAP"
    | "DDP"
    | "N/A"
  );
export type SellMode = number & (1 | 2 | 3 | 4 | 90);
export type Transport = number & (1 | 2 | 3 | 4 | 9);

class Utils {
  private baseUrl = "gw/utils";

  get $axios() {
    return Vue.axios;
  }

  get cacheData() {
    return GwCacheModule.cache;
  }

  cache(sKey: string | Record<string, any>, sValue?: any) {
    if (isString(sKey)) {
      sKey = `Utils.${sKey}`;

      if (!sValue) {
        return get(this.cacheData, sKey);
      }

      if (isObjectLike(sValue)) {
        GwCacheModule.set({ [sKey]: sValue });
        return sValue;
      }
    }

    if (isObjectLike(sKey)) {
      GwCacheModule.set({ Utils: sKey });
    }

    return sKey;
  }

  /**
   * Get next module code as numeric value
   * @param {String} sValue Module name where get next code
   * @returns {Promise<Result<number>>}
   */
  async nextCode(sValue: string): Promise<Result<number>> {
    const obResponse = await this.$axios.get(
      `${this.baseUrl}/next_code/${sValue}`
    );

    return obResponse.data;
  }

  async nextProductCode() {
    return await this.nextCode("product");
  }

  async nextCategoryCode() {
    return await this.nextCode("category");
  }

  async cleanApiCache() {
    const obResponse = await this.$axios.get(`${this.baseUrl}/cleancache`);

    return obResponse.data;
  }

  async downloadUrl(sUrl: string, sFilename: string) {
    const fileLink = document.createElement("a");

    fileLink.href = sUrl;
    fileLink.setAttribute("download", sFilename);
    document.body.appendChild(fileLink);

    fileLink.click();
    fileLink.remove();
  }

  downloadBlob(obData: BlobPart, sFilename: string) {
    const fileURL = window.URL.createObjectURL(new Blob([obData]));
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", sFilename);
    document.body.appendChild(fileLink);

    fileLink.click();
    fileLink.remove();
  }

  blobAsDataUrl(
    obData: BlobPart,
    sMimeType: string = "application/pdf"
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = (event) => {
        const dataUrlPrefix = `data:${sMimeType}`;
        const base64Data = event.target?.result as string | undefined;

        if (!base64Data) {
          return reject(null);
        }

        const arData = base64Data.split(";");
        arData.shift();
        const base64 = `${dataUrlPrefix};${arData.join(";")}`;
        resolve(base64);
      };
      reader.onerror = (error) => {
        reader.abort();
        reject(error);
      };

      reader.readAsDataURL(new Blob([obData]));
    });
  }

  async getIDList(): Promise<IDList> {
    const sKey = "IdList";
    const obData = this.cache(sKey);

    if (obData) {
      return Promise.resolve(obData);
    }

    const obResponse = await this.$axios.get(`${this.baseUrl}/id_list`);

    return this.cache(sKey, obResponse.data.data);
  }

  async getSellClause(): Promise<SellClause[]> {
    const sKey = "SellClause";
    const obData = this.cache(sKey);

    if (obData) {
      return Promise.resolve(obData);
    }

    const obResponse = await this.$axios.get(`${this.baseUrl}/sell_clause`);

    return this.cache(sKey, obResponse.data.data);
  }

  async getSellMode(): Promise<SellMode[]> {
    const sKey = "SellMode";
    const obData = this.cache(sKey);

    if (obData) {
      return Promise.resolve(obData);
    }

    const obResponse = await this.$axios.get(`${this.baseUrl}/sell_mode`);

    return this.cache(sKey, obResponse.data.data);
  }

  async getTransport(): Promise<Transport[]> {
    const sKey = "Transport";
    const obData = this.cache(sKey);

    if (obData) {
      return Promise.resolve(obData);
    }

    const obResponse = await this.$axios.get(`${this.baseUrl}/transport`);

    return this.cache(sKey, obResponse.data.data);
  }

  validateIDByCountry(sCode: CountryCode): IDListKey[] {
    const arIDCodes: IDListKey[] = [];

    switch (sCode) {
      case "AR":
      case "BR":
      case "CL":
      case "PY":
        arIDCodes.push("dni", "passport", "other");
        break;

      case "UY":
        arIDCodes.push("rut", "ci", "nie", "nife", "other");
        break;

      default:
        arIDCodes.push("passport", "other");
        break;
    }

    return arIDCodes;
  }

  validateCountryByID(sCode: IDListKey): CountryCode[] {
    const arCodes: CountryCode[] = [];

    switch (sCode) {
      case "ci":
      case "rut":
      case "nie":
      case "nife":
        arCodes.push("UY");
        break;

      case "dni":
        arCodes.push("AR", "BR", "CL", "PY");
        break;
    }

    return arCodes;
  }
}

export default new Utils();

export const ref = <T = any>(sValue: T): Ref<T> => {
  return Vue.observable<Ref<T>>({ value: sValue });
};
