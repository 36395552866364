export default [
  // ACCOUNT
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accounts",
    name: "accounts.index",
  },
  { methods: ["POST"], uri: "api/v1/gw/accounts", name: "accounts.store" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accounts/list",
    name: "accounts.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accounts/{id}",
    name: "accounts.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/accounts/{id}",
    name: "accounts.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/accounts/{id}",
    name: "accounts.destroy",
  },
];
