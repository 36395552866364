<template>
  <v-list v-if="display && company" color="transparent" flat>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title v-if="!$_.isNil(fUyuBalance)">
          <animated-number
            :formatValue="getCurrencyFormat"
            :value="fUyuBalance"
          />
        </v-list-item-title>
        <v-list-item-subtitle v-if="!$_.isNil(fUsdBalance)">
          <animated-number :formatValue="usdFormat" :value="fUsdBalance" />
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { EventBus } from "@/services/event-bus";
import { currencyFormat } from "@/plugins/helpers";
import { AppModule } from "@/store/app";
import { Component, Vue } from "vue-property-decorator";

import AnimatedNumber from "animated-number-vue";
import type { CompanyBalanceData } from "@planetadeleste/vue-mc-gw";
import { ConfigModule } from "@/store/config";

@Component({
  components: { AnimatedNumber },
})
export default class CompanyBalance extends Vue {
  obBalance: CompanyBalanceData | null = null;

  get company() {
    return AppModule.company;
  }

  get fUsdBalance() {
    return this.obBalance ? this.obBalance.USD : 0;
  }

  get fUyuBalance() {
    return this.obBalance ? this.obBalance.UYU : 0;
  }

  get display() {
    return ConfigModule.showCompanyBalance;
  }

  mounted() {
    this.$nextTick(this.getBalance);
  }

  created() {
    EventBus.on("company.reload.balance", this.getBalance);
    EventBus.on("app.company.selected", this.getBalance);
  }

  beforeDestroy() {
    EventBus.off("company.reload.balance", this.getBalance);
    EventBus.off("app.company.selected", this.getBalance);
  }

  getBalance() {
    if (!this.company || !this.company.id) {
      return;
    }

    this.company.getBalance().then((response) => {
      if (!response || !response.getData()) {
        return;
      }

      this.obBalance = response.getData().data;
    });
  }

  getCurrencyFormat(fValue: number, sCode = "UYU") {
    return currencyFormat(fValue, sCode);
  }

  usdFormat(fValue: number) {
    return this.getCurrencyFormat(fValue, "USD");
  }
}
</script>
