import { Component, Vue } from "vue-property-decorator";
import { AuthModule } from "@/store/auth";
import { usePusher } from "@/services/pusher";

@Component
export default class BroadcastMixin extends Vue {
  obPusher = usePusher();
  channel: string | null = null;
  event: string | null = null;

  get broadcastKey() {
    return this.channel && this.event
      ? `${this.channel}.${this.event}`
      : undefined;
  }

  get user() {
    return AuthModule.user;
  }
}
