<template>
  <v-card flat class="pde-right-nav" v-if="user">
    <v-card-title center>
      <v-avatar class="mx-auto" size="80">
        <img :src="user.avatar" :alt="fullName" v-if="user.avatar" />
        <span v-else>{{ userInitials }}</span>
      </v-avatar>
    </v-card-title>

    <v-card-text class="text-center">
      <h3 class="card-title font-weight-light" v-text="fullName"></h3>
      <v-btn
        to="/account"
        class="font-weight-thin text-lowercase d-block"
        text
        rounded
        color="primary"
      >
        <v-icon>mdi-account</v-icon>
        {{ $t("my.profile") }}
      </v-btn>

      <v-btn color="success" text class="font-weight-light" @click="onLogout">
        <v-icon>mdi-arrows-left-right-bold-outline</v-icon>
        {{ $t("logout") }}
      </v-btn>

      <v-btn @click="avatar" text icon color="primary">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import Auth from "@/mixins/AuthMixin";

@Component
export default class RightDrawer extends Mixins(Auth) {
  isRouterAlive = false;
  onLogout() {
    this.logOut();
    this.reload();
  }

  reload() {
    return (window.location.href = "/");
    // this.isRouterAlive = false;
    // this.$nextTick(() => (this.isRouterAlive = true));
  }

  avatar() {
    this.reloadUserAvatar();
  }
}
</script>
