export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/migrationmodules",
    name: "migrationmodules.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/migrationmodules",
    name: "migrationmodules.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/migrationmodules/list",
    name: "migrationmodules.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/migrationmodules/types",
    name: "migrationmodules.types",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/migrationmodules/{id}",
    name: "migrationmodules.show",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/migrationmodules/collect_tasks/{id}",
    name: "migrationmodules.collect_tasks",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/migrationmodules/restart/{id}",
    name: "migrationmodules.restart",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/migrationmodules/{id}",
    name: "migrationmodules.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/migrationmodules/{id}",
    name: "migrationmodules.destroy",
  },
];
