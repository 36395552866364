export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/pricelists",
    name: "pricelists.index",
  },
  { methods: ["POST"], uri: "api/v1/gw/pricelists", name: "pricelists.store" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/pricelists/list",
    name: "pricelists.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/pricelists/{id}/offers",
    name: "pricelists.offers",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/pricelists/{id}",
    name: "pricelists.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/pricelists/{id}",
    name: "pricelists.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/pricelists/{id}",
    name: "pricelists.destroy",
  },
];
