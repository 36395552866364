export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/contracts",
    name: "contracts.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/contracts",
    name: "contracts.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/contracts/list",
    name: "contracts.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/contracts/{id}",
    name: "contracts.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/contracts/{id}",
    name: "contracts.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/contracts/{id}",
    name: "contracts.destroy",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/contracts/createinvoices/{id?}",
    name: "contracts.createinvoices",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/contractpositions",
    name: "contractpositions.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/contractpositions",
    name: "contractpositions.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/contractpositions/list",
    name: "contractpositions.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/contractpositions/{id}",
    name: "contractpositions.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/contractpositions/{id}",
    name: "contractpositions.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/contractpositions/{id}",
    name: "contractpositions.destroy",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/contractpositions/{id}/invoices",
    name: "contractpositions.invoices",
  },
];
