<template>
  <ValidationProvider
    ref="fieldTaxType"
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    slim
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="iTaxTypeId"
        :chips="multiple"
        :clearable="clearable"
        :error-messages="errors"
        :items="items"
        :label="$t(label)"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :multiple="multiple"
        :readonly="readonly"
        :small-chips="multiple"
        :success="valid"
        deletable-chips
        dense
        hide-details="auto"
        item-text="name"
        item-value="id"
        outlined
        v-bind="$attrs"
        @change="onChange"
      >
        <template #item="data">
          <tax-type-preview :item="data.item" menu />
        </template>
      </v-select>
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { AppModule } from "@/store/app";
import type { TaxType } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { castArray, filter, find, isArrayLike, isEmpty } from "lodash";
import { TaxTypeModule } from "@/store/taxtype";
import TaxTypePreview from "@/modules/taxtypes/components/TaxTypePreview.vue";

@Component({
  components: { TaxTypePreview },
})
export default class TaxTypeSelect extends Vue {
  @VModel({ type: [Number, Array] }) iTaxTypeId!: number | number[];
  @Prop({ type: String, default: "taxtype" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly all!: boolean;
  @Prop(Boolean) readonly withValue!: boolean;
  @Prop(Boolean) readonly readonly!: boolean;
  @Prop({ type: Boolean, default: true }) readonly clearable!: boolean;

  loading = false;

  get companySettings() {
    return AppModule.companySettings;
  }

  get taxTypes() {
    return TaxTypeModule.items;
  }

  get items() {
    return filter(this.taxTypes, (obModel: TaxType) => {
      let bReturn = true;

      if (this.companySettings?.tax_types.length) {
        bReturn = this.companySettings.tax_types.includes(obModel.id);
      }

      return this.withValue && bReturn ? obModel.percent > 0 : bReturn;
    });
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    if (this.taxTypes?.length) {
      return;
    }

    // const obFilters: Record<string, any> = { company: this.company.id };

    this.loading = true;
    await TaxTypeModule.load();
    this.loading = false;
    this.$emit("update:items", this.items);
  }

  onChange(iValue: number | number[]) {
    if (!iValue || (isArrayLike(iValue) && isEmpty(iValue))) {
      this.$emit("input", this.multiple ? [] : 0);
      this.$emit("change", null);

      return;
    }

    if (this.multiple) {
      iValue = castArray(iValue) as number[];

      this.$emit(
        "change",
        // @ts-ignore
        filter(this.taxTypes, (obModel: TaxType) => iValue.includes(obModel.id))
      );
    } else {
      this.$emit("change", find(this.taxTypes, { id: iValue }));
    }

    this.$emit("input", iValue);
  }
}
</script>
