import { create, setChildren } from "@/router/utils";
import { cloneDeep, set } from "lodash";

const obRoute = create("users", null, "index", {
  sortOrder: 790,
  iconProps: { icon: "users" },
});

const arChildList = [
  setChildren("users", "list", cloneDeep(obRoute.meta), "list", ""),
  setChildren("users", "update", cloneDeep(obRoute.meta), "list", "update/:id"),
  setChildren("users", "create", cloneDeep(obRoute.meta), "list", "create"),
  setChildren(
    "users",
    "loggedin",
    cloneDeep(obRoute.meta),
    "logged_in",
    "loggedin"
  ),
];
set(obRoute, "children", arChildList);

export default [obRoute];
