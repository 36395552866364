export default [
  // ACCOUNT TYPE
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accounttypes",
    name: "accounttypes.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accounttypes",
    name: "accounttypes.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accounttypes/list",
    name: "accounttypes.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accounttypes/{id}",
    name: "accounttypes.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/accounttypes/{id}",
    name: "accounttypes.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/accounttypes/{id}",
    name: "accounttypes.destroy",
  },
];
