<template>
  <v-sheet class="dt-footer" v-if="total">
    <v-divider></v-divider>
    <v-row wrap class="align-center">
      <v-col cols="12" sm="4" lg="3" class="pl-8 dt-footer__total">
        <div>
          {{ $t("total", { number: total }) }}
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        lg="9"
        v-if="lastPage > 1"
        class="dt-footer__pagination"
      >
        <v-pagination
          right
          v-model="page"
          :length="lastPage"
          :total-visible="7"
          @input="paginate"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class FooterTable extends Vue {
  @Prop(Number) readonly total!: number;
  @Prop(Number) readonly lastPage!: number;
  @Prop(Number) value!: number;

  get page() {
    return this.value;
  }

  set page(value) {
    this.$emit("input", value);
  }

  paginate(value: number) {
    this.page = value;
  }
}
</script>
