import { limited } from "@/router/permissions";
import { create } from "@/router/utils";

const obRoute = create(
  "paymentterms",
  null,
  "index",
  {
    sortOrder: 530,
    iconProps: { icon: "cash-outline" },
  },
  limited
);

export default [obRoute];
