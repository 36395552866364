export default [
  // Invoice Position
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicepositions",
    name: "invoicepositions.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoicepositions",
    name: "invoicepositions.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicepositions/list",
    name: "invoicepositions.list",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/invoicepositions/{id}",
    name: "invoicepositions.destroy",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/invoicepositions/{id}",
    name: "invoicepositions.update",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicepositions/{id}",
    name: "invoicepositions.show",
  },

  // Invoice
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoices",
    name: "invoices.index",
  },
  { methods: ["POST"], uri: "api/v1/gw/invoices", name: "invoices.store" },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/get_type",
    name: "invoices.get_type",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/set_balance",
    name: "invoices.set_balance",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/{id}/update_paymentmethods",
    name: "invoices.update_paymentmethods",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/{id}/update_processed",
    name: "invoices.update_processed",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/email/{id}",
    name: "invoices.send_email",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/copy/{id}",
    name: "invoices.copy",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/refund/{id}",
    name: "invoices.refund",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/charge/{id}",
    name: "invoices.charge",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/cancel/{id}",
    name: "invoices.cancel",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/rebuild_xml/{id}",
    name: "invoices.rebuild_xml",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoices/list",
    name: "invoices.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoices/count",
    name: "invoices.count",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/invoices/{id}",
    name: "invoices.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/invoices/{id}",
    name: "invoices.destroy",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoices/{id}",
    name: "invoices.show",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoices/children/{id}",
    name: "invoices.children",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoices/tree/{id}",
    name: "invoices.tree",
  },

  // Print or download Invoice PDF
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoices/print/{uuid}",
    name: "invoices.print",
  },

  // Get invoice XML
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoices/xml/{uuid}",
    name: "invoices.xml",
  },

  // Invoices Report
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoices/report/{report_type}/{report_format}",
    name: "invoices.report",
  },

  // Invoice list unique values by column
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoices/list_type/{type}/{company_id?}",
    name: "invoices.list_type",
  },

  // Invoice Type
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoicetypes",
    name: "invoicetypes.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicetypes",
    name: "invoicetypes.index",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicetypes/list",
    name: "invoicetypes.list",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/invoicetypes/{id}",
    name: "invoicetypes.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/invoicetypes/{id}",
    name: "invoicetypes.destroy",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicetypes/{id}",
    name: "invoicetypes.show",
  },
];
