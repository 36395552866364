import Vue from "vue";
import type { Route } from "vue-router";
import VueRouter from "vue-router";
import type { RouteConfigLocal } from "./routes";
import routes from "./routes";
import { i18n, loadLanguageAsync } from "@/plugins/i18n";
import { BASEURL } from "./base";
import generateTypedRoutes from "./generateTypedRoutes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: BASEURL,
  routes,
});

if (
  process.env.NODE_ENV === "development" &&
  process.env.VUE_APP_WRITE_ROUTE_TYPES === "true"
) {
  generateTypedRoutes(router.getRoutes() as RouteConfigLocal[]);
}

// Set translations
router.beforeEach(async (to: Route, from: Route, next: CallableFunction) => {
  const lang = to.params.lang || i18n.locale;

  if (lang) {
    await loadLanguageAsync(lang);
  }

  await next();
});

export default router;
