import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import type { InvoiceMovementTypeData } from "@planetadeleste/vue-mc-gw";
import {
  InvoiceMovementType as InvoiceMovementTypeModel,
  InvoiceMovementTypeCollection,
} from "@planetadeleste/vue-mc-gw";
import { map } from "lodash";
import { number } from "mathjs";

export interface MovementTypeState {
  _items: Partial<InvoiceMovementTypeData>[];
}

@Module({
  name: "MovementType",
  dynamic: true,
  namespaced: true,
  store: store,
  preserveState: localStorage.getItem("vuex") !== null,
})
class InvoiceMovementType extends VuexModule implements MovementTypeState {
  isLoading = false;
  _items: Partial<InvoiceMovementTypeData>[] = [];

  get items(): Partial<InvoiceMovementTypeData>[] {
    return this._items;
  }

  get collection() {
    return new InvoiceMovementTypeCollection(
      this._items.map((obItem) => new InvoiceMovementTypeModel(obItem))
    );
  }

  get models() {
    return this.collection.getModels();
  }

  @Action
  async load(obFilters: Record<string, any> = {}) {
    // prevent multiple calls
    if (this.isLoading) return;

    this.setLoadingState(true);

    try {
      const obCollection = new InvoiceMovementTypeCollection();
      await obCollection.filterBy(obFilters).list();
      this.setItemsState(
        obCollection.getModelList() as InvoiceMovementTypeData[]
      );
    } catch (e) {
      console.error("An error occurred:", e);
    }

    this.setLoadingState(false);
  }

  // Updating Loading Fields State
  @Mutation
  setLoadingState(state: boolean) {
    this.isLoading = state;
  }

  @Mutation
  setItemsState(arData: InvoiceMovementTypeData[]) {
    this._items = map(arData, (obItem) => {
      obItem.code = number(obItem.code) as unknown as string;

      return obItem;
    });
  }
}

export const MovementTypeModule = getModule(InvoiceMovementType);
