<template>
  <v-container :class="cssClass" fluid>
    <v-responsive class="mx-auto fill-height">
      <v-row :class="{ 'mx-lg-10': prominent }">
        <v-col cols="12">
          <breadcrumbs />
        </v-col>

        <v-col cols="12">
          <sheet
            :hide-top-attached="hideFilters"
            :no-padding="hideSheet"
            :no-wrap-default-slot="dense"
            :transparent="hideSheet"
            dense
          >
            <template v-if="showToolbar" #head>
              <v-toolbar color="transparent" flat tile>
                <v-toolbar-title v-text="title" />
                <!-- <v-divider class="mx-2" inset vertical /> -->
                <v-spacer />
                <v-btn
                  v-if="!hideNewButton && pathCreate"
                  :disabled="loading"
                  :to="pathCreate"
                  color="primary"
                  depressed
                >
                  <icon-add class="mr-2" />
                  {{ $t("add.new") }}
                </v-btn>

                <v-btn
                  v-if="showReloadButton"
                  color="primary"
                  icon
                  @click="onReload"
                >
                  <icon-refresh-cw />
                </v-btn>

                <slot name="buttons" />

                <v-slide-x-transition>
                  <v-btn
                    v-if="showBtnBack && pathBack"
                    :to="pathBack"
                    class="ml-2"
                    color="primary"
                    text
                    v-text="$t('back')"
                  />
                </v-slide-x-transition>
              </v-toolbar>
            </template>

            <template #top-attached>
              <slot name="filters" />
            </template>

            <v-scroll-x-transition>
              <slot name="route" />
            </v-scroll-x-transition>

            <slot />
          </sheet>
        </v-col>
      </v-row>
    </v-responsive>
  </v-container>
</template>

<script lang="ts">
import { EventBus } from "@/services/event-bus";
import { Component, Prop, Vue } from "vue-property-decorator";
import { LoadingModule } from "@/store/loading";

/**
 * Global container for index components
 * Use v-container, v-toolbar and v-scroll-x-transition on router-view
 */
@Component
export default class ModuleContainer extends Vue {
  /**
   * Items to exclude from cache on keep-alive router-view
   * @type {String}
   * @default ""
   */
  @Prop({ default: "", type: String }) readonly exclude!: string;

  /**
   * Title for toolbar
   * @type {String}
   */
  @Prop(String) readonly title!: string;

  /**
   * Path for back, create buttons
   * @type {String}
   */
  @Prop(String) readonly path!: string;

  /**
   * Base route name for back and create buttons
   * @type {String}
   */
  @Prop(String) readonly routeName!: string;

  /**
   * Route name for create button
   * @type {String}
   */
  @Prop(String) readonly routeNameCreate!: string;

  /**
   * Hide create button
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly hideNewButton!: boolean;

  /**
   * Hide back button
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly hideBackButton!: boolean;

  /**
   * Hide reload button
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly hideReloadButton!: boolean;

  /**
   * Force to show reload button
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly showReloadButton!: boolean;

  /**
   * Hide sheet component
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly hideSheet!: boolean;

  /**
   * Hide filters component
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly hideFilters!: boolean;

  /**
   * Display Toolbar
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly showToolbar!: boolean;

  /**
   * Extra padding on container
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly prominent!: boolean;

  /**
   * Remove container wrap element of default slot
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly dense!: boolean;

  get loading() {
    return LoadingModule.getLoading;
  }

  get showBtnBack() {
    return !this.hideBackButton && this.$route.params.id;
  }

  get hasFilters() {
    return !!this.$_.get(this.$slots, "filters") && !this.hideFilters;
  }

  get pathCreate() {
    if (this.routeNameCreate) {
      return this.$router.resolve({ name: this.routeNameCreate }).href;
    }

    if (this.routeName && this.$route.name !== `${this.routeName}.create`) {
      return this.$router.resolve({ name: `${this.routeName}.create` }).href;
    }

    if (this.path) {
      return `/${this.path}/create`;
    }

    return null;
  }

  get pathBack() {
    if (this.routeName) {
      const sRouteName = this.hasRoute(this.routeName)
        ? this.routeName
        : `${this.routeName}.list`;
      return this.$router.resolve({ name: sRouteName }).href;
    }

    if (this.path) {
      return `/${this.path}`;
    }

    return null;
  }

  get className() {
    if (this.routeName) {
      return this.routeName;
    }

    return this.$_.chain(this.path).split("/").first().value();
  }

  get cssClass() {
    const obClassData = {
      "align-start": true,
      "fill-height": true,
    };

    if (this.className) {
      this.$_.set(obClassData, `container--${this.className}`, true);
    }

    return obClassData;
  }

  hasRoute(sName: string) {
    const obRoute = this.$router.resolve({ name: sName });
    return obRoute.resolved.matched.length > 0;
  }

  onReload() {
    EventBus.emit("reload.index");
  }
}
</script>
