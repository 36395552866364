import { create, setChildren } from "@/router/utils";
import { cloneDeep, set } from "lodash";
import { company } from "@/router/permissions";

const obRoute = create("companies", null, "index", {
  sortOrder: 700,
  iconProps: { icon: "business-outline" },
});

const arChildList = obRoute.children;
if (arChildList && arChildList.length) {
  const obCurrenciesMeta = cloneDeep(obRoute.meta);
  const obCurrencyRatesMeta = cloneDeep(obRoute.meta);
  const obInvoiceTypesMeta = cloneDeep(obRoute.meta);
  const obCfeCodesMeta = cloneDeep(obRoute.meta);
  const obPaymentMethodMeta = cloneDeep(obRoute.meta);

  set(obCurrenciesMeta, "iconProps.icon", "coins");
  set(obCurrencyRatesMeta, "iconProps.icon", "coins");
  set(obCurrencyRatesMeta, "permissions", company);
  set(obInvoiceTypesMeta, "iconProps.icon", "invoice");
  set(obCfeCodesMeta, "iconProps.icon", "text-outline");
  set(obPaymentMethodMeta, "iconProps.icon", "credit-card");

  arChildList.push(
    setChildren(
      "companies",
      "settings",
      cloneDeep(obRoute.meta),
      "settings",
      "settings"
    )
  );

  arChildList.push(
    setChildren(
      "companies",
      "currency_rates",
      obCurrencyRatesMeta,
      "currency_rates",
      "currency_rates"
    )
  );

  arChildList.push(
    setChildren(
      "companies",
      "currencies",
      obCurrenciesMeta,
      "currencies",
      "currencies"
    )
  );

  arChildList.push(
    setChildren(
      "companies",
      "invoice_types",
      obInvoiceTypesMeta,
      "invoice_types",
      "invoice_types"
    )
  );

  arChildList.push(
    setChildren(
      "companies",
      "cfe_codes",
      obCfeCodesMeta,
      "cfe_codes",
      "cfe_codes"
    )
  );

  arChildList.push(
    setChildren(
      "companies",
      "paymentmethods",
      obPaymentMethodMeta,
      "payment_methods",
      "paymentmethods"
    )
  );
}

export default [obRoute];
