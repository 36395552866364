export default [
  // ACCOUNTING ENTRY
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentries",
    name: "accountingentries.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accountingentries",
    name: "accountingentries.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentries/list",
    name: "accountingentries.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentries/{id}",
    name: "accountingentries.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/accountingentries/{id}",
    name: "accountingentries.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/accountingentries/{id}",
    name: "accountingentries.destroy",
  },
  // ACCOUNTING ENTRY COLUMN
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentrycolumns",
    name: "accountingentrycolumns.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accountingentrycolumns",
    name: "accountingentrycolumns.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentrycolumns/list",
    name: "accountingentrycolumns.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentrycolumns/{id}",
    name: "accountingentrycolumns.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/accountingentrycolumns/{id}",
    name: "accountingentrycolumns.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/accountingentrycolumns/{id}",
    name: "accountingentrycolumns.destroy",
  },
  // ACCOUNTING ENTRY POSITION
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentrypositions",
    name: "accountingentrypositions.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accountingentrypositions",
    name: "accountingentrypositions.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentrypositions/list",
    name: "accountingentrypositions.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingentrypositions/{id}",
    name: "accountingentrypositions.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/accountingentrypositions/{id}",
    name: "accountingentrypositions.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/accountingentrypositions/{id}",
    name: "accountingentrypositions.destroy",
  },

  // ACCOUNTING MOVEMENT TYPE
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingmovementtypes",
    name: "accountingmovementtypes.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accountingmovementtypes",
    name: "accountingmovementtypes.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingmovementtypes/list",
    name: "accountingmovementtypes.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountingmovementtypes/{id}",
    name: "accountingmovementtypes.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/accountingmovementtypes/{id}",
    name: "accountingmovementtypes.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/accountingmovementtypes/{id}",
    name: "accountingmovementtypes.destroy",
  },
];
