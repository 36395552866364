import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import type {
  Module as ModuleModel,
  ModuleData,
} from "@planetadeleste/vue-mc-gw";
import { ModuleCollection } from "@planetadeleste/vue-mc-gw";

export interface ModulesState {
  _items: Partial<ModuleData>[];
}

@Module({
  name: "Modules",
  dynamic: true,
  namespaced: true,
  store: store,
})
class Modules extends VuexModule implements ModulesState {
  public isLoading = false;

  _items: Partial<ModuleData>[] = [];

  get items(): Partial<ModuleData>[] {
    return this._items;
  }

  get collection() {
    const obCollection = new ModuleCollection();
    obCollection.add(this.items);

    return obCollection;
  }

  get models(): ModuleModel[] {
    return this.collection.getModels();
  }

  get codes(): string[] {
    return this.collection.map("code");
  }

  @Action
  async load(obFilters: Record<string, any> = {}) {
    // prevent multiple calls
    if (this.isLoading) return;

    this.setLoadingState(true);

    try {
      const obCollection = new ModuleCollection();
      await obCollection.filterBy(obFilters).list();
      this.setItemsState(obCollection.getModelList() as ModuleData[]);
    } catch (e) {
      console.error("An error occurred:", e);
    }

    this.setLoadingState(false);
  }

  // Updating Loading Fields State
  @Mutation
  private setLoadingState(state: boolean) {
    this.isLoading = state;
  }

  @Mutation
  private setItemsState(arData: ModuleData[]) {
    this._items = arData;
  }
}

export const ModulesModule = getModule(Modules);
