export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/cfecodes",
    name: "cfecodes.index",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/cfecodes/list",
    name: "cfecodes.list",
  },

  { methods: ["POST"], uri: "api/v1/gw/cfecodes", name: "cfecodes.store" },

  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/cfecodes/{id}",
    name: "cfecodes.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/cfecodes/{id}",
    name: "cfecodes.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/cfecodes/{id}",
    name: "cfecodes.destroy",
  },
];
