import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import router from "@/router";
import {
  get,
  has,
  indexOf,
  isObject,
  isUndefined,
  set,
  startsWith,
} from "lodash";
import { AuthModule } from "@/store/auth";
import { LayoutModule } from "@/store/layout";
import { FlashModule } from "@/store/flash";
import { setupCache, buildMemoryStorage } from "axios-cache-interceptor";
import { number } from "mathjs";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const config = require("@/config");
const ENV = process.env.NODE_ENV;
const { API_ENDPOINT } = ENV ? get(config, ENV) : "";

const cacheStorage = buildMemoryStorage();
const axios = setupCache(Axios, {
  storage: cacheStorage,
  headerInterpreter: (headers) => {
    if (has(headers, "x-ratelimit-limit")) {
      let secs = number(get(headers, "x-ratelimit-limit", 30) as number);
      secs = secs > 30 ? 30 : secs;

      return secs * 1000;
    }

    return "dont cache";
  },
  ttl: 30 * 1000,
});

axios.defaults.baseURL = API_ENDPOINT;
set(axios.defaults.headers.common, "Access-Control-Allow-Origin", "*");

axios.interceptors.request.use(
  (reqConfig) => {
    if (!reqConfig || isUndefined(reqConfig.url)) {
      return reqConfig;
    }

    // clean the url
    if (reqConfig.url && startsWith(reqConfig.url, API_ENDPOINT)) {
      const url = reqConfig.url.replace(API_ENDPOINT, "");
      set(reqConfig, "url", url);
    }

    // Add Headers
    if (startsWith(reqConfig.baseURL, API_ENDPOINT)) {
      // Add Bearer auth
      if (localStorage.getItem("access_token")) {
        set(
          reqConfig.headers,
          "Authorization",
          `Bearer ${localStorage.getItem("access_token")}`
        );
      }

      // Add refresh token
      if (reqConfig.url.includes("/auth/logout")) {
        set(
          reqConfig.headers,
          "X-REFRESH-TOKEN",
          localStorage.getItem("refresh_token")
        );
      }

      // Add CSRF TOKEN
      set(reqConfig.headers, "X-CSRF-TOKEN", AuthModule.csrfToken);
      set(reqConfig.headers, "X-ENV", "backend");

      // Add X-GW-CID (Company ID)
      const sCompanyID = localStorage.getItem("cid");
      if (sCompanyID) {
        set(reqConfig.headers, "X-GW-CID", sCompanyID);
      }
      // Add X-GW-CBID (Company Branch ID)
      const sBranchID = localStorage.getItem("cbid");
      if (sBranchID) {
        set(reqConfig.headers, "X-GW-CBID", sBranchID);
      }

      // Add readonly invoice header
      if (
        !!router.currentRoute.name &&
        ["invoices.view", "invoices.debt.view"].includes(
          router.currentRoute.name
        ) &&
        router.currentRoute.params.id
      ) {
        set(
          reqConfig.headers,
          "X-GW-INVREADONLY",
          router.currentRoute.params.id
        );
      }
    }

    return reqConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const forceLogout = () => {
  if (router.currentRoute.name !== "account.login") {
    router.push({ name: "account.login" });
  }

  AuthModule.logout();
  LayoutModule.setLayout("login");
};

const cleanCache = () => {
  cacheStorage.cleanup();
  cacheStorage.data = {};
};

axios.interceptors.response.use(
  (reqConfig) => {
    return reqConfig;
  },

  (err) => {
    const data = get(err, "response.data");

    if (isObject(err.response.config)) {
      const url = get(err, "response.config.url");

      if (
        url.includes("/login") ||
        url.includes("/refresh") ||
        url.includes("/invalidate")
      ) {
        if (data) {
          if (has(data, "error") || has(data, "message")) {
            // FlashModule.error(data.error || data.message);
            err = data;
          }
        }

        return Promise.reject(err);
      }
    }

    if (indexOf([400, 401], err.response.status) !== -1) {
      forceLogout();
    }

    if (data) {
      if (has(data, "error") || has(data, "message")) {
        FlashModule.error(data.error || data.message);
        err = data;
      }
    }

    return Promise.reject(err);
  }
);

Vue.use(VueAxios, axios);

export { axios, cacheStorage, cleanCache };
