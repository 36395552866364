<template>
  <form-field-text
    v-model="sValue"
    v-bind="$attrs"
    :input-type="show ? 'text' : 'password'"
    @save="save"
  >
    <template #append>
      <v-btn icon @click="show = !show" class="ma-0 mt-n1 pa-0">
        <icon-eye-outline size="1.3rem" v-if="show" />
        <icon-eye-off-outline size="1.3rem" v-else />
      </v-btn>
    </template>
  </form-field-text>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import FormFieldText from "@/components/form/fields/Text.vue";

@Component({
  components: { FormFieldText },
})
export default class FormFieldPassword extends Vue {
  @VModel({ type: String }) sValue!: string;
  show = false;

  save(sValue: string) {
    this.$emit("save", sValue);
  }
}
</script>
