import { create, setChildren } from "@/router/utils";
import { cloneDeep, set } from "lodash";

const obRoute = create(
  "account",
  null,
  "index",
  {
    sortOrder: 800,
    iconProps: { icon: "person-circle-outline" },
    title: "my.account.data",
  },
  false,
  false
);

const arChildList = [
  setChildren("account", "account", cloneDeep(obRoute.meta), "account", ""),
  setChildren("account", "login", cloneDeep(obRoute.meta), "login", "/login"),
  setChildren("account", "forget", cloneDeep(obRoute.meta), "login", "/forget"),
  setChildren(
    "account",
    "restore",
    cloneDeep(obRoute.meta),
    "login",
    "/restore/:slug?"
  ),
];
set(obRoute, "children", arChildList);

export default [obRoute];
