export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/contractcategories",
    name: "contractcategories.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/contractcategories",
    name: "contractcategories.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/contractcategories/list",
    name: "contractcategories.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/contractcategories/{id}",
    name: "contractcategories.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/contractcategories/{id}",
    name: "contractcategories.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/contractcategories/{id}",
    name: "contractcategories.destroy",
  },
];
