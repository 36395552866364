import Vue from "vue";
import Vuetify from "vuetify/lib";
import { es } from "vuetify/es5/locale";
import { Ripple } from "vuetify/lib/directives";
import { i18n } from "@/plugins/i18n";
import { toString } from "lodash";
import { dotcase } from "./helpers";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es",
    t: (key, ...params) => {
      const name = dotcase(key);
      return toString(i18n.t(name, params));
    },
  },
  directives: {
    Ripple,
  },
  theme: {
    themes: {
      light: {
        primary: "#1e40af",
        secondary: "#91d798",
      },
      dark: {
        primary: "#1e40af",
        secondary: "#91d798",
      },
    },
  },
});
