export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicemovementtypes",
    name: "invoicemovementtypes.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoicemovementtypes",
    name: "invoicemovementtypes.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicemovementtypes/list",
    name: "invoicemovementtypes.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicemovementtypes/{id}",
    name: "invoicemovementtypes.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/invoicemovementtypes/{id}",
    name: "invoicemovementtypes.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/invoicemovementtypes/{id}",
    name: "invoicemovementtypes.destroy",
  },
];
