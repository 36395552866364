<template>
  <v-app class="pde-app">
    <v-main>
      <v-container fill-height fluid>
        <v-row class="align-center justify-center">
          <v-col class="text-center" cols="12" md="4" sm="8">
            <v-progress-circular color="primary" indeterminate />
            <div
              v-show="loadingTitle"
              class="subtitle-1 mt-4 grey--text"
              v-text="loadingTitle"
            ></div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <loading />
  </v-app>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { LayoutModule } from "@/store/layout";
import { AuthModule } from "@/store/auth";
import Auth from "@/mixins/AuthMixin";
import { AppModule } from "@/store/app";
import { ConfigModule } from "@/store/config";
import { Company, type CompanyData } from "@planetadeleste/vue-mc-gw";
import Loading from "@/components/common/loading/index.vue";
import { ModulesModule } from "@/store/modules";
import { number } from "mathjs";
import { AccountModule } from "@/store/account";
import { InvoiceTypeModule } from "@/store/invoicetype";
import { TaxTypeModule } from "@/store/taxtype";
import { MovementTypeModule } from "@/store/movementtype";
import { isEmpty, isNil } from "lodash";

@Component({
  components: { Loading },
})
export default class InitLayout extends Mixins(Auth) {
  public loadingTitle = "";

  public mounted() {
    this.$nextTick(this.onPreload);
  }

  goLogin() {
    const arRoutes: string[] = [
      "account.login",
      "account.forget",
      "account.restore",
    ];
    if (!this.$route.name || !arRoutes.includes(this.$route.name)) {
      this.$router.push({ name: "account.login" });
    }

    LayoutModule.setLayout("login");
  }

  async fetchCompany() {
    const arUserCompanyIdList = this.user.get(
      "company_list_id",
      []
    ) as number[];
    const iCurrentCompanyId = number(localStorage.getItem("cid"));
    let sCompanyID = iCurrentCompanyId;

    if (
      this.userIsCompany &&
      !arUserCompanyIdList.includes(iCurrentCompanyId)
    ) {
      sCompanyID = arUserCompanyIdList[0];
    }

    if (sCompanyID && AppModule.companyId !== sCompanyID) {
      const obCompany = new Company({ id: sCompanyID as number });
      await obCompany.fetch();
      await AppModule.pickCompany(obCompany.toJSON() as CompanyData);
    }

    if (!AccountModule.items.length) {
      AccountModule.setLoadingState(false);
      AccountModule.load({ company: AppModule.companyId }).then();
    }
    const iBranchId = this.user.get("default_branch") as number;
    await AppModule.pickBranch(iBranchId);
  }

  private async onPreload() {
    if (
      isNil(ConfigModule.settings?.attributes) ||
      isEmpty(ConfigModule.settings?.attributes)
    ) {
      await ConfigModule.load();
    }

    if (!AuthModule.isLogged) {
      return this.goLogin();
    }

    // validate server logged
    const logged = await this.authCheck();

    if (!logged) {
      return this.goLogin();
    }

    if (!AppModule.currencies?.length) {
      await AppModule.loadCurrencies();
    }

    if (!AppModule.groups?.length) {
      await AppModule.loadGroups();
    }

    if (!InvoiceTypeModule.items?.length) {
      InvoiceTypeModule.setLoadingState(false);
      await InvoiceTypeModule.load();
    }

    if (!TaxTypeModule.items?.length) {
      TaxTypeModule.setLoadingState(false);
      await TaxTypeModule.load();
    }

    if (!MovementTypeModule.items?.length) {
      MovementTypeModule.setLoadingState(false);
      await MovementTypeModule.load();
    }

    await ModulesModule.load();

    await this.fetchCompany();

    await this.initSession();
  }
}
</script>
