export default [
  // MailLog
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/maillogs",
    name: "maillogs.index",
  },
  { methods: ["POST"], uri: "api/v1/gw/maillogs", name: "maillogs.store" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/maillogs/list",
    name: "maillogs.list",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/maillogs/{id}",
    name: "maillogs.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/maillogs/{id}",
    name: "maillogs.destroy",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/maillogs/{id}",
    name: "maillogs.show",
  },

  // Get XML
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/maillogs/xml/{id}",
    name: "maillogs.xml",
  },
];
