import { admin, company, limited } from "@/router/permissions";
import { create, setChildren } from "@/router/utils";
import { cloneDeep, set } from "lodash";

// Invoices
const obInvoicesRoute = create(
  "invoices",
  null,
  "index",
  {
    sortOrder: 99,
    iconProps: { icon: "invoice", original: true },
    title: "saved.invoices",
  },
  limited,
  false
);

const arChildList = [
  setChildren(
    "invoices",
    "create",
    cloneDeep(obInvoicesRoute.meta),
    "form",
    "create"
  ),
  setChildren(
    "invoices",
    "update",
    cloneDeep(obInvoicesRoute.meta),
    "form",
    "update/:id"
  ),
  setChildren(
    "invoices",
    "view",
    cloneDeep(obInvoicesRoute.meta),
    "form",
    "view/:id"
  ),
  setChildren(
    "invoices",
    "copy",
    cloneDeep(obInvoicesRoute.meta),
    "form",
    "copy/:id"
  ),
  setChildren(
    "invoices",
    "correct",
    cloneDeep(obInvoicesRoute.meta),
    "form",
    "correct/:id"
  ),
  setChildren(
    "invoices",
    "charge",
    cloneDeep(obInvoicesRoute.meta),
    "form",
    "charge/:id"
  ),
];
set(obInvoicesRoute, "children", arChildList);

// EMMITED INVOICES
const obInvoicesEmittedRoute = create(
  "invoices.emitted",
  "/invoices/emitted",
  "emitted",
  {
    sortOrder: 91,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
  },
  limited,
  false
);

// SAVED INVOICES
const obInvoicesSavedRoute = create(
  "invoices.saved",
  "/invoices/saved",
  "saved",
  {
    sortOrder: 99,
    iconProps: { icon: "invoice-alt", original: true },
    module: "invoices",
  },
  limited,
  false
);

// RECEIVED INVOICES
const obInvoicesReceivedRoute = create(
  "invoices.received",
  "/invoices/received",
  "received",
  {
    sortOrder: 92,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
  },
  company,
  false
);

const obInvoicesReceivedUnprocessedRoute = create(
  "invoices.received.unprocessed",
  "/invoices/received/unprocessed",
  "received_unprocessed_list",
  {
    sortOrder: 92,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
  },
  company,
  false
);

const obInvoicesReceivedListRoute = create(
  "invoices.received.list",
  "/invoices/received/list",
  "received_list",
  {
    sortOrder: 92,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
  },
  company,
  false
);

const arReceivedChildList = [
  setChildren(
    "invoices",
    "received",
    cloneDeep(obInvoicesReceivedRoute.meta),
    "received",
    ""
  ),
  setChildren(
    "invoices",
    "view",
    cloneDeep(obInvoicesReceivedRoute.meta),
    "received_form",
    "view/:id"
  ),
];

set(obInvoicesReceivedRoute, "children", arReceivedChildList);

// DEBT INVOICES
const obInvoicesDebtRoute = create(
  "invoices.debt",
  "/invoices/debt",
  "debt",
  {
    sortOrder: 93,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
    title: "debt.collection",
  },
  limited,
  false
);

const obInvoicesDebtEmmitedRoute = create(
  "invoices.debt.emitted",
  "/invoices/debt/emitted",
  "debt_emitted",
  {
    sortOrder: 93,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
    title: "debt.collection",
  },
  limited,
  false
);

const obInvoicesDebtReceivedRoute = create(
  "invoices.debt.received",
  "/invoices/debt/received",
  "debt_received",
  {
    sortOrder: 93,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
    title: "debt.collection",
  },
  limited,
  false
);

const obInvoicesDebtSavedRoute = create(
  "invoices.debt.saved",
  "/invoices/debt/saved",
  "debt_saved",
  {
    sortOrder: 93,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
    title: "debt.collection",
  },
  limited,
  false
);

const arDebtChildList = [
  setChildren(
    "invoices",
    "debt",
    cloneDeep(obInvoicesDebtRoute.meta),
    "debt",
    ""
  ),
  setChildren(
    "invoices",
    "create",
    cloneDeep(obInvoicesDebtRoute.meta),
    "debt_form",
    "create"
  ),
  setChildren(
    "invoices",
    "update",
    cloneDeep(obInvoicesDebtRoute.meta),
    "debt_form",
    "update/:id"
  ),
  setChildren(
    "invoices",
    "view",
    cloneDeep(obInvoicesDebtRoute.meta),
    "debt_form",
    "view/:id"
  ),
];

set(obInvoicesDebtRoute, "children", arDebtChildList);

// RECEIPT INVOICES
const obInvoicesReceiptRoute = create(
  "invoices.receipt",
  "/invoices/receipt",
  "receipt",
  {
    sortOrder: 93,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
    title: "receipt.collection",
  },
  admin,
  false
);
const obInvoicesReceiptSavedRoute = create(
  "invoices.receipt.saved",
  "/invoices/receipt/saved",
  "receipt_saved",
  {
    sortOrder: 93,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
    title: "receipt.collection",
  },
  admin,
  false
);

const obInvoicesReceiptEmittedRoute = create(
  "invoices.receipt.emitted",
  "/invoices/receipt/emitted",
  "receipt_emitted",
  {
    sortOrder: 93,
    iconProps: { icon: "invoice", original: true },
    module: "invoices",
    title: "receipt.collection",
  },
  admin,
  false
);

const arReceiptChildList = [
  setChildren(
    "invoices",
    "receipt",
    cloneDeep(obInvoicesReceiptRoute.meta),
    "receipt",
    ""
  ),
  setChildren(
    "invoices",
    "create",
    cloneDeep(obInvoicesReceiptRoute.meta),
    "receipt_form",
    "create"
  ),
  setChildren(
    "invoices",
    "update",
    cloneDeep(obInvoicesReceiptRoute.meta),
    "receipt_form",
    "update/:id"
  ),
];

set(obInvoicesReceiptRoute, "children", arReceiptChildList);

export default [
  obInvoicesRoute,
  obInvoicesEmittedRoute,
  obInvoicesSavedRoute,
  obInvoicesReceivedRoute,
  obInvoicesReceivedListRoute,
  obInvoicesReceivedUnprocessedRoute,
  obInvoicesDebtRoute,
  obInvoicesDebtEmmitedRoute,
  obInvoicesDebtReceivedRoute,
  obInvoicesDebtSavedRoute,
  obInvoicesReceiptRoute,
  obInvoicesReceiptSavedRoute,
  obInvoicesReceiptEmittedRoute,
];
