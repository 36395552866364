<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <!-- <template #activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-icon><icon-settings /></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t('company.settings')" />
        </v-list-item-content>
      </v-list-item>
    </template> -->
    <v-card>
      <v-card-text>
        <v-form class="fill-height" @submit.prevent v-if="settings && dialog">
          <gw-form-observer
            @save="save"
            @cancel="close"
            :loading="loading"
            hide-top-actions
          >
            <settings-form v-model="settings" />
          </gw-form-observer>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import SettingsForm from "@/modules/companies/components/SettingsForm.vue";
import { AppModule } from "@/store/app";
import { type CompanyData, CompanySettings } from "@planetadeleste/vue-mc-gw";

@Component({
  components: { SettingsForm },
})
export default class CompanySettingsDialog extends Vue {
  @VModel({ type: Boolean, default: false }) dialog!: boolean;

  loading = false;
  obSettings: CompanySettings = new CompanySettings();

  mounted() {
    this.$nextTick(() => {
      this.obSettings.set("company_id", this.company.id);
    });

    this.obSettings.on("change", () => {
      if (!this.obSettings.isDirty() || !this.dialog) {
        return;
      }

      this.obSettings.set("company_id", this.company.id);
      this.obSettings.sync();

      this.company.set("settings", this.obSettings.attributes);
      this.save();
    });
  }

  async save() {
    if (!this.dialog) {
      return;
    }

    this.loading = true;
    this.company.sync();
    const obResponse = await this.company.store();

    if (obResponse) {
      const obCompany = obResponse.getData().data as CompanyData;
      AppModule.setCompany(obCompany);
    }

    this.loading = false;
  }

  close() {
    this.dialog = false;
  }

  get company() {
    return AppModule.company;
  }

  get settings(): CompanySettings {
    if (!this.obSettings) {
      return this.obSettings;
    }

    const obSettings = this.company.get("settings");

    if (this.$_.isEmpty(obSettings) || !this.$_.isObject(obSettings)) {
      return this.obSettings;
    }

    if (obSettings instanceof CompanySettings) {
      this.obSettings.assign(obSettings.attributes);
    } else {
      this.obSettings.assign(obSettings);
    }

    return this.obSettings;
  }

  set settings(obData: CompanySettings) {
    if (!this.obSettings) {
      return;
    }

    const obSettingsData =
      obData instanceof CompanySettings ? obData.attributes : obData;
    this.obSettings.assign(obSettingsData);
  }
}
</script>
