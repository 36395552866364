import Ucfe from "@/services/Ucfe";
import { isEmpty, isNil } from "lodash";

export default function useRUT(sValue: string | number) {
  const clean = (sVal?: string | number) => {
    if (!sVal) {
      sVal = sValue;
    }

    const sRut = typeof sVal === "number" ? sVal.toString() : sVal;
    return sRut.replace(/\D/g, "");
  };

  const validate = (): boolean => {
    if (isNil(sValue)) {
      return false;
    }

    let sRut = clean(sValue);

    if (sRut.length != 12) {
      return false;
    }

    if (!/^([0-9])*$/.test(sRut)) {
      return false;
    }

    const dc = Number(sRut.substr(11, 1));
    sRut = sRut.substr(0, 11);
    let total = 0;
    let factor = 2;

    for (let i = 10; i >= 0; i--) {
      total += factor * Number(sRut.substr(i, 1));
      factor = factor == 9 ? 2 : ++factor;
    }

    let dv = 11 - (total % 11);

    if (dv == 11) {
      dv = 0;
    } else if (dv == 10) {
      dv = 1;
    }

    return dv == dc;
  };

  /**
   * @description Load firm data from DGI WS
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @param {boolean} [bToCompanyData=false] Set true to convert response to Firm data object
   * @return {*}
   */
  const loadFirmData = async (bToCompanyData?: boolean) => {
    const sRut = clean(sValue);
    const obResponse = await Ucfe.getFirmData(sRut, bToCompanyData);

    return isEmpty(obResponse.data) ? obResponse.message : obResponse.data;
  };

  return { validate, loadFirmData, clean };
}
