export default [
  // - PAYMENT TERMS
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/paymentterms",
    name: "paymentterms.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/paymentterms",
    name: "paymentterms.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/paymentterms/list",
    name: "paymentterms.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/paymentterms/{id}",
    name: "paymentterms.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/paymentterms/{id}",
    name: "paymentterms.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/paymentterms/{id}",
    name: "paymentterms.destroy",
  },
];
