import type { RouteConfigLocal } from "@/router/routes";
import type { Route } from "vue-router";
import type { RouteNamedMap } from "@/types/typed-router";

export interface MenuItem {
  title: string;
  to?: keyof RouteNamedMap;
  key?: string;
  children?: MenuItem[];
  route?: Route | RouteConfigLocal;
  group?: RegExp;
  devel?: boolean;
  create?: keyof RouteNamedMap;
}

const arMenuItems: MenuItem[] = [
  { title: "home", to: "home" },

  {
    // COMPROBANTES
    title: "invoices",
    group: /^\/invoices.*/,
    children: [
      {
        // EMITIDOS
        title: "invoices.emitted",
        group: /^\/invoices\/(emitted|saved)$/,
        create: "invoices.emitted",
        children: [
          { title: "invoices.movements", to: "invoices.emitted" },
          { title: "invoices.saved", to: "invoices.saved" },
        ],
      },
      {
        // RECIBIDOS
        title: "invoices.received",
        group: /^\/invoices\/received.*/,
        children: [
          { title: "invoices.movements", to: "invoices.received.list" },
          {
            title: "invoices.unprocessed",
            to: "invoices.received.unprocessed",
          },
        ],
      },
      {
        // RECIBOS
        title: "receipts",
        group: /^\/invoices\/debt.*/,
        children: [
          {
            // COBRANZA
            title: "debt.collection",
            group: /^\/invoices\/debt.*/,
            create: "invoices.debt.emitted",
            children: [
              { title: "invoices.movements", to: "invoices.debt.emitted" },
              { title: "invoices.saved", to: "invoices.debt.saved" },
              { title: "invoices.debt.received", to: "invoices.debt.received" },
            ],
          },

          {
            // PAGO
            title: "invoices.paid",
            group: /^\/invoices\/receipt.*/,
            create: "invoices.receipt.emitted",
            children: [
              // {
              //   title: "invoice.receipt.create",
              //   to: "invoices.receipt.create",
              // },
              { title: "invoice.movements", to: "invoices.receipt.emitted" },
              { title: "invoice.saved", to: "invoices.receipt.saved" },
            ],
          },
        ],
      },
    ],
  },

  {
    // PRODUCTOS, CATEGORIAS Y LISTAS DE PRECIOS
    title: "products",
    children: [
      { title: "products", to: "products.list" },
      { title: "categories", to: "categories.list" },
      { title: "pricelists", to: "pricelists.list" },
      {
        title: "reports",
        children: [
          { title: "report.sales", to: "reports.sales" },
          { title: "report.purchase" },
        ],
      },
    ],
  },

  {
    // CLIENTES, CONTRATOS, REPORTES Y UTILIDADES.
    title: "customers",
    children: [
      { title: "customers", to: "customers.list" },
      { title: "contracts", to: "contracts.list" },
      { title: "contract.categories", to: "contractcategories.list" },
      {
        title: "reports",
        children: [
          { title: "pendings", to: "reports.unpaid" },
          { title: "account.statement", to: "reports.accountstatement" },
          { title: "balances", to: "reports.balance" },
        ],
      },
      {
        title: "utils",
        children: [{ title: "rounding.adjustment" }],
      },
    ],
  },

  {
    // CONTABILIDAD.
    title: "accounting",
    children: [
      { title: "accounts", to: "accounts.list" },
      { title: "account.tax", to: "accounttaxes.list" },
      { title: "account.receipts", to: "accountreceipts.list" },
      { title: "accountconfigs", to: "accountconfigs.list" },
      {
        title: "accounting.entries.configuration",
        to: "accountingentries.list",
      },
      {
        title: "accounting.entries.generation",
        to: "accountingentryinvoices.list",
      },

      {
        title: "reports",
        children: [
          { title: "subdaily", to: "reports.subdaily" },
          { title: "receipt", to: "reports.receipt" },
          { title: "report.unpaid", to: "reports.unpaid" },
          { title: "report.sales", to: "reports.sales" },
          { title: "financial", children: [{ title: "payment.methods" }] },
        ],
      },
    ],
  },

  {
    // CONFIGURACIONES
    title: "settings",
    children: [
      { title: "my.account.data", to: "account.account" },
      { title: "company.currency.rates", to: "companies.currency_rates" },
      { title: "paymentterms", to: "paymentterms.list" },
      { title: "company.paymentmethods", to: "companies.paymentmethods" },
      { title: "warehouses", to: "warehouses.list" },
      { title: "vendors.collectors", to: "vendorcollectors.list" },
      { title: "company.settings", to: "companies.settings" },
      { title: "reportjob", to: "reportjob.list" },
    ],
  },

  {
    // ADMINISTRACIÓN
    title: "admin",
    children: [
      /*{
        title: "companies",
        children: [
          { title: "companies.list", to: "companies.list" },
          { title: "company.currencies", to: "companies.currencies" },
          { title: "company.invoices", to: "companies.invoice_types" },
          { title: "company.cfecodes", to: "companies.cfe_codes" },
        ],
      },*/
      { title: "companies", to: "companies.list" },
      { title: "currencies", to: "currencies.list" },
      { title: "taxtypes", to: "taxtypes.list" },
      { title: "paymentmethods", to: "paymentmethods.list" },
      { title: "banks", to: "banks.list" },
      { title: "cfecodes", to: "cfecodes.list" },
      { title: "users", to: "users.list" },
      { title: "logged.users", to: "users.loggedin" },
      { title: "audits", to: "audits.list" },
      { title: "settings", to: "settings" },
      // {
      //   title: "temporary",
      //   children: [
      //     {
      //       title: "debt.collection",
      //       children: [
      //         { title: "invoices.debt.emitted", to: "invoices.debt.emitted" },
      //         { title: "invoices.debt.saved", to: "invoices.debt.saved" },
      //         { title: "invoices.debt.received", to: "invoices.debt.received" },
      //       ],
      //     },
      //   ],
      // },
      { title: "migrations", to: "migrations.list" },
    ],
  },
];

export default arMenuItems;
