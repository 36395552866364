<template>
  <div v-frag>
    <template v-if="root">
      <template v-for="item in items">
        <template v-if="item.children">
          <nav-group
            :key="`main-menu-group-${item.key}`"
            :item="item"
            :items="item.children"
            :level="level + 1"
          >
            <!-- Pass on all scoped slots -->
            <template
              v-for="(_, name) in $scopedSlots"
              v-slot:[name]="slotProps"
            >
              <slot :name="name" v-bind="slotProps" />
            </template>
          </nav-group>
          <v-divider :key="`main-group-divider-${item.key}`" />
        </template>
        <template v-else-if="item.to || item.route">
          <slot :item="item" :name="`item-${item.key}`">
            <nav-item :key="`main-item-${item.key}`" :item="item" />
          </slot>
        </template>
      </template>
    </template>

    <v-list-group
      v-else
      :class="`v-list-group--level-${level}`"
      :value="!sItemLabel"
      :group="sGroup"
      v-bind="$attrs"
    >
      <template v-if="sLabel" #activator>
        <v-list-item-content>
          <v-list-item-title v-text="sLabel" />
        </v-list-item-content>
        <v-list-item-icon class="justify-end align-center" v-if="item.create">
          <invoice-btn-create nav :route="item.create" />
        </v-list-item-icon>
      </template>

      <template v-if="icon && (level < 2 || !arItems)" #prependIcon>
        <component :is="icon" size="1.2em" />
      </template>

      <template v-for="(item, index) in arItems">
        <template v-if="item.children">
          <v-divider :key="`sub-group-divider-top-${index}-${item.key}`" />
          <nav-group
            :key="`sub-menu-group-${index}-${item.key}`"
            :item="item"
            :level="level + 1"
            no-action
            sub-group
          >
            <!-- Pass on all scoped slots -->
            <template
              v-for="(_, name) in $scopedSlots"
              v-slot:[name]="slotProps"
            >
              <slot :name="name" v-bind="slotProps" />
            </template>
          </nav-group>
          <v-divider :key="`sub-group-divider-down-${index}-${item.key}`" />
        </template>

        <template v-else>
          <slot :item="item" :name="`item-${item.key}`">
            <nav-item :key="`${index}-${item.key}`" :item="item" />
          </slot>
        </template>
      </template>
    </v-list-group>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import NavItem from "@/layout/components/main/drawer/NavItem.vue";
import type { MenuItem } from "@/config/mainMenu";
import { get } from "lodash";
import InvoiceBtnCreate from "@/modules/invoices/components/InvoiceBtnCreate.vue";

@Component({
  name: "NavGroup",
  components: { InvoiceBtnCreate, NavItem, NavGroup },
})
export default class NavGroup extends Vue {
  @Prop(Array) readonly items!: MenuItem[];
  @Prop(Object) readonly item!: MenuItem;
  @Prop(String) readonly label!: string;
  @Prop(Boolean) readonly root!: boolean;
  @Prop({ type: Number, default: 0 }) readonly level!: number;

  obIcons: Record<string, any> = {
    accounting: "icon-balance",
    products: "icon-boxes",
    customers: "icon-restaurant",
    companies: "icon-restaurant",
    settings: "icon-settings",
    invoices: "icon-investment",
    receipts: "icon-bill",
    "debt.collection": "icon-bill",
    reports: "icon-pie-report",
    admin: "icon-lock-closed-outline",
  };

  get sItemLabel() {
    return this.label ?? this.item?.title;
  }

  get arItems() {
    return this.items ?? this.item?.children;
  }

  get sLabel() {
    return this.sItemLabel
      ? this.$t(`nav.group.${this.sItemLabel}`)
      : undefined;
  }

  get sGroup() {
    return this.item?.group;
  }

  get icon() {
    return get(this.obIcons, this.sItemLabel);
  }

  get arScopedSlots() {
    return this.$scopedSlots;
  }
}
</script>
