<template>
  <file-pond
    :id="elementId"
    ref="pondFile"
    :name="name"
    accepted-file-types="image/jpeg, image/png"
    :style-panel-layout="multiple ? 'grid' : 'integrated'"
    :class="{
      'filepond--multiple': multiple,
      'filepond--hide-buttons': hideButtons,
      'filepond--hide-info': hideFileInfo,
    }"
    :label-idle="labelIdle"
    :instant-upload="false"
    :allow-browse="true"
    :allow-file-encode="true"
    :allow-image-preview="true"
    :allow-paste="false"
    :allow-process="false"
    :allow-multiple="multiple"
    :files="files"
    @updatefiles="handleUpdate"
    @init="handleInit"
  />
</template>

<script lang="ts">
import { Vue, Component, Ref, Prop, VModel } from "vue-property-decorator";
import { FilePond } from "./FilePond";
import type { FilePond as FilePondRef, FilePondFile } from "filepond";
import type { FileData } from "@planetadeleste/vue-mc";
import {
  isEmpty,
  filter,
  map,
  chain,
  has,
  get,
  uniqueId,
  isString,
  isArray,
} from "lodash";

type ElementFile =
  | string
  | null
  | FileData
  | FileData[]
  | FilePondFile
  | FilePondFile[]
  | File[]
  | File;

/**
 * Add image upload using FilePond plugin.
 * Can upload a simple image, multiple images or both
 *
 * @displayName FileUpload
 */
@Component({
  components: {
    FilePond,
  },
})
export default class FileUpload extends Vue {
  /**
   * Property name for simple image
   * @type {String}
   * @default "preview_image"
   */
  @Prop({ type: String, default: "preview_image" }) readonly name!: string;

  /**
   * Allow multiple uploads on the same instance
   * @default false
   */
  @Prop(Boolean) readonly multiple!: boolean;

  /**
   * Hide FilePond buttons
   * @default false
   */
  @Prop(Boolean) readonly hideButtons!: boolean;

  /**
   * Hide File info on upload
   * @default false
   */
  @Prop(Boolean) readonly hideFileInfo!: boolean;

  /**
   * Element object for v-model value
   * @model
   * @type {ElementFile}
   * @default {}
   */
  @VModel() file!: ElementFile;

  @Ref("pondFile") readonly pondFile!: FilePondRef;

  initImageIds: string[] = [];
  initPreviewId: string | null = null;

  handleUpdate(files: FilePondFile[]) {
    if (this.multiple) {
      this.handleMultipleUpdate(files);
      return;
    }

    if (!files.length) {
      this.$emit("input", null);
      return;
    }

    const obImage = files[0];
    const obFile = obImage.id != this.initPreviewId ? obImage.file : null;
    if (!isEmpty(obFile)) {
      this.$emit("input", obFile);
    }
  }

  getFiles() {
    return filter(this.pondFile.getFiles(), (file) => !isEmpty(file));
  }

  handleInit() {
    const images = this.getFiles();
    if (this.multiple) {
      this.initImageIds = images.length ? map(images, (file) => file.id) : [];
    } else {
      this.initPreviewId = images.length ? images[0].id : null;
    }
  }

  handleMultipleUpdate(files: FilePondFile[]) {
    if (!files.length) {
      this.$emit("input", []);
      return;
    }

    const images = chain(files)
      .filter((file: FilePondFile) => {
        return !this.initImageIds.includes(file.id);
      })
      .map((file) => file.file)
      .value();

    if (!isEmpty(images)) {
      this.$emit("input", images);
    }
  }

  setLocalSource(sSource: string) {
    return sSource;
  }

  browse() {
    this.pondFile.browse();
  }

  remove(sIndex?: string | number) {
    this.pondFile.removeFile(sIndex);
  }

  get labelIdle() {
    const sBrowse = this.$t("browse");
    const sLabel = this.$t("drag.and.drop.files");
    return `${sLabel} <span class="filepond--label-action">${sBrowse}</span>`;
  }

  get files() {
    if (this.multiple) {
      return this.imagesFiles;
    }

    if (!this.file) {
      return [];
    }

    const obImage = isString(this.file)
      ? this.setLocalSource(this.file)
      : this.file;

    return [obImage];
  }

  get imagesFiles() {
    if (isEmpty(this.file) || !isArray(this.file)) {
      return [];
    }

    return map(this.file, (image) => {
      return has(image, "path") ? get(image, "path") : image;
    });
  }

  get elementId() {
    return uniqueId("itemPondFile");
  }
}
</script>
