<template>
  <ValidationProvider
    ref="textValidator"
    :name="$t(label)"
    :rules="sRules"
    :vid="sVid"
    slim
  >
    <template #default="{ errors, valid }">
      <v-text-field
        ref="textField"
        v-model="name"
        v-mask="mask"
        :error-messages="errors"
        :hide-details="hideDetails"
        :inputmode="sInputMode"
        :label="hideLabel ? undefined : $t(label)"
        :success="required ? valid : undefined"
        :type="sInputType"
        v-bind="obAttrs"
        @blur="onBlur"
        @change="onChange"
        @input="onInput"
        @keyup.enter="save"
        @click:clear="onClear"
      >
        <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
      </v-text-field>
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, Ref, VModel, Vue } from "vue-property-decorator";
import type { DebounceFunction } from "@/plugins/helpers";
import type { ValidationProvider } from "vee-validate";
import type { VTextField } from "vuetify/lib/components";
import {
  camelCase,
  debounce,
  defaults,
  has,
  isNil,
  isObject,
  isString,
  set,
} from "lodash";

@Component
export default class FormFieldText extends Vue {
  @VModel({ type: [String, Number], default: "" }) name!: string;
  @Prop({ type: String, default: "name" }) readonly label!: string;
  @Prop(Boolean) readonly hideLabel!: boolean;
  @Prop({ type: String }) readonly vid!: string;
  @Prop({ type: [String, Object], default: "" }) readonly rules!:
    | string
    | Record<string, any>;
  @Prop({ type: String, default: "text" }) readonly inputType!: string;
  @Prop({ type: String, default: "" }) readonly mask!: string;
  @Prop({ type: [String, Number], default: 500 }) readonly wait!: number;
  @Prop(Boolean) readonly required!: boolean;
  @Prop({ type: [Boolean, String], default: "auto" }) readonly hideDetails!:
    | boolean
    | "auto";

  @Ref("textValidator") readonly obValidator!: InstanceType<
    typeof ValidationProvider
  >;
  @Ref("textField") readonly obVTextField!: InstanceType<typeof VTextField>;

  fnDebounceInput!: DebounceFunction;
  fnDebounceChange!: DebounceFunction;
  tempValue: string | number | undefined = undefined;

  get sRules() {
    if (this.required) {
      if (isString(this.rules) && !this.rules.includes("required")) {
        return this.rules.length ? `required|${this.rules}` : "required";
      } else if (isObject(this.rules) && !has(this.rules, "required")) {
        set(this.rules, "required", true);
      }
    }

    return this.rules;
  }

  get sVid() {
    return isNil(this.vid) ? camelCase(this.label) : this.vid;
  }

  get sInputMode(): string {
    let sMode = "text";

    switch (this.inputType) {
      case "number":
        sMode = "decimal";
        break;

      case "integer":
        sMode = "number";
        break;
    }

    return sMode;
  }

  get sInputType(): string {
    let sType = this.inputType || "text";

    if (this.inputType === "number" && this.sInputMode !== "text") {
      sType = "text";
    }

    return sType;
  }

  get isSuccess(): boolean {
    // @ts-ignore
    return !!this.obVTextField && !this.obVTextField.hasError;
  }

  get obAttrs(): Record<string, any> {
    return defaults(this.$attrs, {
      dense: true,
      outlined: true,
    });
  }

  onChange(sValue: string | number) {
    sValue = this.sanitizeValue(sValue);
    this.tempValue = sValue;

    this.$emit("change", sValue);
    this.fnDebounceChange();
  }

  onInput(sValue: string | number) {
    sValue = this.sanitizeValue(sValue);
    this.tempValue = sValue;

    this.$emit("input", sValue);
    this.fnDebounceInput();
  }

  onBlur(ev: FocusEvent) {
    const elInput: HTMLInputElement | null = ev.target as HTMLInputElement;

    /**
     * Emitted when the input is blurred
     * @param {string|number}
     */
    this.$emit("blur", elInput.value);
  }

  onClear(ev: Event) {
    /**
     * Emitted when clearable icon clicked
     * @param {Event}
     */
    this.$emit("click:clear", ev);
  }

  async validate(sValue?: string | number) {
    if (this.obValidator) {
      return await this.obValidator.validate(sValue);
    }

    return true;
  }

  created() {
    this.fnDebounceChange = debounce(() => {
      /**
       * Emmited when input change by user interaction debounced by wait value (500ms default)
       * @param {string|number}
       */
      this.$emit("change:debounce", this.tempValue);
    }, this.wait);

    this.fnDebounceInput = debounce(() => {
      /**
       * The updated bound model debounced by wait value (500ms default)
       * @param {string|number}
       */
      this.$emit("input:debounce", this.tempValue);
    }, this.wait);
  }

  save() {
    if (!this.isSuccess) {
      return;
    }

    /**
     * Emmited on enter key
     * @param {string|number}
     */
    this.$emit("save", this.tempValue);
  }

  sanitizeValue(sValue: string | number) {
    if (this.inputType === "number" && isString(sValue)) {
      sValue = parseFloat(sValue.replace(/[^\d.-]/g, ""));
    }

    return sValue;
  }
}
</script>
