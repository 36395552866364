<template>
  <v-progress-circular :color="color" :size="size" width="2" indeterminate />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconLoading extends Vue {
  @Prop({ type: String, default: "#e3f2fd" }) readonly color!: string;
  @Prop({ type: [String, Number], default: 16 }) readonly size!:
    | string
    | number;
}
</script>
