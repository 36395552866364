export default [
  // AUDITS
  { uri: "api/v1/audits", name: "audits.store", methods: ["POST"] },
  { uri: "api/v1/audits", name: "audits.index", methods: ["GET", "HEAD"] },
  {
    uri: "api/v1/audits/{id}",
    name: "audits.destroy",
    methods: ["DELETE"],
  },
  {
    uri: "api/v1/audits/{id}",
    name: "audits.update",
    methods: ["PUT", "PATCH"],
  },
  {
    uri: "api/v1/audits/{id}",
    name: "audits.show",
    methods: ["GET", "HEAD"],
  },
];
