export default [
  // ACCOUNT TYPE
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountreceipts",
    name: "accountreceipts.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accountreceipts",
    name: "accountreceipts.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountreceipts/list",
    name: "accountreceipts.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountreceipts/{id}",
    name: "accountreceipts.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/accountreceipts/{id}",
    name: "accountreceipts.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/accountreceipts/{id}",
    name: "accountreceipts.destroy",
  },
];
