export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/releases",
    name: "releases.index",
  },
  { methods: ["POST"], uri: "api/v1/gw/releases", name: "releases.store" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/releases/list",
    name: "releases.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/releases/latest",
    name: "releases.latest",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/releases/{id}",
    name: "releases.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/releases/{id}",
    name: "releases.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/releases/{id}",
    name: "releases.destroy",
  },
];
