export default [
  {
    path: "*",
    page: "404",
    name: "error.404",
    meta: {
      module: "error",
      title: "Error 404",
      icon: "lni-warning",
      drawer: false,
      main: false,
    },
  },
];
