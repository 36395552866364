import { limited } from "@/router/permissions";
import { create } from "@/router/utils";

const obRoute = create(
  "reportjob",
  null,
  "index",
  {
    sortOrder: 320,
    iconProps: { icon: "list", original: true },
  },
  limited
);

export default [obRoute];
