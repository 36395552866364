export default function useCI(sValue: string | number) {
  const generateValidationDigit: (idNumber: string | number) => number = (
    idNumber
  ) => {
    let ci = typeof idNumber === "number" ? idNumber.toString() : idNumber;

    for (let i = 0; i < 7 - ci.length; i++) {
      ci = "0" + ci;
    }

    return safeGenerateValidationDigit(ci);
  };

  const safeGenerateValidationDigit: (idNumber: string) => number = (
    idNumber
  ) => {
    const ci = idNumber;
    let sum = 0;

    for (let i = 0; i < 7; i++) {
      sum += parseInt("2987634"[i]) * parseInt(ci[i]);
    }

    if (sum % 10 === 0) {
      return 0;
    }

    return 10 - (sum % 10);
  };

  const clean = (sVal?: string | number) => {
    if (!sVal) {
      sVal = sValue;
    }

    sVal = typeof sVal === "number" ? sVal.toString() : sVal;
    return sVal.replace(/\D/g, "");
  };

  const validate = (): boolean => {
    const ci = typeof sValue === "number" ? sValue.toString() : sValue;
    const cleanNumber = clean(ci);

    if (cleanNumber.length < 7 || cleanNumber.length > 8) {
      return false;
    }

    const possibleValidationDigit = parseInt(
      cleanNumber[cleanNumber.length - 1],
      10
    );

    const validableNumber = cleanNumber.replace(/[0-9]$/, "");
    const actualValidationDigit = generateValidationDigit(validableNumber);

    return possibleValidationDigit === actualValidationDigit;
  };

  const random = () => {
    const base = Math.floor(Math.random() * 10000000 + 1000000).toString();
    return base.substring(0, 7) + generateValidationDigit(base);
  };

  return { validate, random, clean };
}
