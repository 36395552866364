export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountconfigs",
    name: "accountconfigs.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accountconfigs",
    name: "accountconfigs.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountconfigs/list",
    name: "accountconfigs.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accountconfigs/{id}",
    name: "accountconfigs.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/accountconfigs/{id}",
    name: "accountconfigs.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/accountconfigs/{id}",
    name: "accountconfigs.destroy",
  },
];
