import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import type {
  CountryData,
  StateData,
  TownData,
} from "@planetadeleste/vue-mc-location";
import {
  Countries,
  Country,
  State,
  States,
  Town,
  Towns,
} from "@planetadeleste/vue-mc-location";
import type { Response } from "vue-mc";

@Module({
  dynamic: true,
  store,
  name: "location",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") !== null,
})
class Location extends VuexModule {
  private _countries: CountryData[] = [];

  get countries() {
    return new Countries(
      this._countries.map(
        (obData) => new Country(obData as Record<string, any>)
      )
    );
  }

  private _states: StateData[] = [];

  get states() {
    return new States(
      this._states.map((obData) => new State(obData as Record<string, any>))
    );
  }

  private _towns: TownData[] = [];

  get towns() {
    return new Towns(
      this._towns.map((obData) => new Town(obData as Record<string, any>))
    );
  }

  get defaultCountry() {
    return this.countries.find({ is_default: true });
  }

  get defaultState() {
    return this.states.find({ is_default: true });
  }

  @Action
  async loadCountries() {
    const obCollection = new Countries();
    await obCollection.filterBy({ active: 1 }).fetch();
    this.setCountries(obCollection.getModelList() as CountryData[]);
  }

  @Action
  async loadStates(iCountryID?: number) {
    const obCountry = (
      iCountryID ? this.countries.find({ id: iCountryID }) : this.defaultCountry
    ) as Country | undefined;

    this.setStates([]);

    if (!obCountry) return;

    if (obCountry.get("states", []).length) {
      this.setStates(obCountry.get("states", []) as StateData[]);
    } else {
      obCountry.getStates().then((response: Response<StateData[]>) => {
        if (response) {
          const obData = response.getData();
          this.setStates((obData.data || obData) as StateData[]);
        }
      });
    }
  }

  @Action
  async loadTowns(iStateID?: number) {
    const obState = iStateID
      ? this.states.find({ id: iStateID })
      : this.defaultState;

    this.setTowns([]);

    if (!obState) return;

    obState.getTowns().then((response) => {
      if (response) {
        const obData = response.getData();
        this.setTowns((obData.data || obData) as TownData[]);
      }
    });
  }

  @Mutation
  setCountries(value: CountryData[]) {
    this._countries = value;
  }

  @Mutation
  setStates(value: StateData[]) {
    this._states = value;
  }

  @Mutation
  setTowns(value: TownData[]) {
    this._towns = value;
  }
}

export const LocationModule = getModule(Location);
