<template>
  <v-list-item
    :to="obRoute ? obRoute.path : undefined"
    color="primary"
    exact-path
  >
    <v-list-item-content>
      <v-list-item-title>
        <slot
          :title="item.title"
          :translatedTitle="$t(item.title)"
          name="title"
        >
          {{ $t(item.title) }}
        </slot>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="obMeta">
      <slot name="action">
        <component
          :is="obMeta.icon"
          v-if="obMeta.iconComponent && obMeta.iconProps"
          size="1.4em"
          stroke="1"
          v-bind="obMeta.iconProps"
        />
        <v-icon v-else>{{ obMeta.icon }}</v-icon>
      </slot>
    </v-list-item-action>
    <v-list-item-icon v-if="obRoute && loadingTo === obRoute.path">
      <v-progress-circular color="primary" indeterminate size="16" width="2" />
    </v-list-item-icon>
  </v-list-item>
</template>
<script lang="ts">
import type { MenuItem } from "@/config/mainMenu";
import type { RouteConfigLocal } from "@/router/routes";
import { LoadingModule } from "@/store/loading";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavItem extends Vue {
  @Prop(Object) readonly item!: MenuItem;

  get loadingTo() {
    return LoadingModule.getLoadingTo;
  }

  get obRoute() {
    return this.item.route as RouteConfigLocal | undefined;
  }

  get obMeta(): Record<string, any> {
    return this.obRoute ? this.obRoute.meta : {};
  }
}
</script>
