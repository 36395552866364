<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import type { Icon } from "@yzfe/svgicon";
import { isEmpty, set } from "lodash";

@Component({})
export default class SvgIcon extends Vue {
  @Prop(String) readonly icon!: string;
  @Prop({ type: String, default: "ionicons" }) readonly path!: string;
  @Prop({ type: [String, Object] }) readonly data!: Icon;
  @Prop(Boolean) readonly outlined!: boolean;
  @Prop({ default: "1" }) readonly stroke!: number | string;
  @Prop({ default: "1em" }) readonly size!: string;

  get css() {
    const classes: string[] = [];

    if (this.outlined) {
      classes.push("outlined");
    }

    if (this.stroke) {
      let strokeWidth = Number(this.stroke);
      if (strokeWidth > 2) {
        strokeWidth = 2;
      } else if (strokeWidth < 0) {
        strokeWidth = 0;
      }
      classes.push(`tw-stroke-${strokeWidth}`);
    }

    return classes;
  }

  get attributes() {
    const attrs = this.$attrs;

    if (this.size) {
      set(attrs, "width", this.size);
      set(attrs, "height", this.size);
    }

    if (this.outlined) {
      set(attrs, "fill", false);
    }

    set(attrs, "data", this.dataPath);

    return attrs;
  }

  get dataPath() {
    if (!isEmpty(this.data)) {
      return this.data;
    }

    if (isEmpty(this.icon)) {
      return null;
    }

    let arPath = ["@icon"];
    const sIcon = this.icon + ".svg";

    if (this.path.includes("/") || this.path.includes("@")) {
      arPath = [this.path];
    } else {
      arPath.push(this.path);
    }

    arPath.push(sIcon);
    return arPath.join("/");
  }
}
</script>

<template>
  <icon :class="css" v-bind="attributes" v-if="dataPath" />
</template>
