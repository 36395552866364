<template>
  <form-field-text
    input-type="email"
    v-model="email"
    :label="label"
    :rules="obRules"
    @save="save"
    v-bind="$attrs"
  />
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import FormFieldText from "@/components/form/fields/Text.vue";

@Component({
  components: { FormFieldText },
})
export default class FormFieldEmail extends Vue {
  @VModel({ type: String, default: "" }) email!: string;
  @Prop({ type: String, default: "email" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;

  get obRules() {
    return {
      email: !this.multiple,
      required: this.required,
      email_multiple: this.multiple,
    };
  }

  save(sValue: string) {
    this.$emit("save", sValue);
  }
}
</script>
