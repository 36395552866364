<template>
  <v-overlay
    :absolute="absolute"
    :value="isLoading"
    :color="light ? '#E3F2FD' : '#212121'"
  >
    <v-progress-circular :size="size" color="primary" indeterminate />
  </v-overlay>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { LoadingModule } from "@/store/loading";

@Component({ name: "loading" })
export default class Loading extends Vue {
  @Prop(Boolean) readonly local!: boolean;
  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Boolean) readonly absolute!: boolean;
  @Prop(Boolean) readonly light!: boolean;
  @Prop({ type: [String, Number], default: 64 }) readonly size!:
    | string
    | number;

  get isLoading(): boolean {
    return this.local ? this.loading : LoadingModule.getLoading;
  }
}
</script>
