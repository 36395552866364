export default [
  {
    path: "/brands",
    name: "brands",
    page: "index",
    meta: {
      sortOrder: 200,
      module: "brands",
      title: "brands",
      group: "products",
      drawer: false,
      main: false,
      iconComponent: true,
      iconProps: { icon: "pricetags-outline", original: true },
      breadcrumb: {
        label: "brands",
        parent: "home",
      },
      permissions: [
        {
          role: "guest",
          access: false,
          redirect: "error-404",
        },
        {
          role: "admin",
          access: true,
        },
      ],
    },
    children: [
      {
        path: "",
        page: "list",
        name: "list",
        meta: {
          title: "list.brands",
          breadcrumb: {
            label: "list.brands",
          },
        },
      },
      {
        path: "update/:id",
        page: "list",
        name: "update",
        meta: {
          title: "update.brand",
          breadcrumb: {
            label: "update.brand",
          },
        },
      },
      {
        path: "view/:id",
        page: "list",
        name: "view",
        meta: {
          title: "view.brand",
          breadcrumb: {
            label: "view.brand",
          },
        },
      },
      {
        path: "create",
        page: "list",
        name: "create",
        meta: {
          title: "create.brand",
          breadcrumb: {
            label: "create.brand",
          },
        },
      },
    ],
  },
];
