export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicemovementtypeconfigs",
    name: "invoicemovementtypeconfigs.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoicemovementtypeconfigs",
    name: "invoicemovementtypeconfigs.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicemovementtypeconfigs/list",
    name: "invoicemovementtypeconfigs.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicemovementtypeconfigs/{id}",
    name: "invoicemovementtypeconfigs.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/invoicemovementtypeconfigs/{id}",
    name: "invoicemovementtypeconfigs.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/invoicemovementtypeconfigs/{id}",
    name: "invoicemovementtypeconfigs.destroy",
  },
];
