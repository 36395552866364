export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/vendorcollectors",
    name: "vendorcollectors.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/vendorcollectors",
    name: "vendorcollectors.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/vendorcollectors/list",
    name: "vendorcollectors.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/vendorcollectors/{id}",
    name: "vendorcollectors.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/vendorcollectors/{id}",
    name: "vendorcollectors.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/vendorcollectors/{id}",
    name: "vendorcollectors.destroy",
  },
];
