export default [
  // ACCOUNT TYPE
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accounttaxes",
    name: "accounttaxes.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/accounttaxes",
    name: "accounttaxes.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accounttaxes/list",
    name: "accounttaxes.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/accounttaxes/{id}",
    name: "accounttaxes.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/accounttaxes/{id}",
    name: "accounttaxes.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/accounttaxes/{id}",
    name: "accounttaxes.destroy",
  },
];
