import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import {
  assign,
  get,
  has,
  isEmpty,
  isFinite,
  isNil,
  isUndefined,
} from "lodash";
import { dotcase } from "./helpers";

Vue.use(VueI18n);

const loadedLanguages: Array<any> = [];
const loadedMissing: Record<string, any> = {};

export const i18n = new VueI18n({
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  locale: "es",
  fallbackLocale: "en",
  missing: (lang, message) => {
    message = dotcase(message);

    if (isEmpty(message) || isFinite(Number(message)) || isNil(message)) {
      return message;
    }

    if (!has(loadedMissing, lang)) {
      assign(loadedMissing, { [lang]: {} });
    }

    const loadedMsgs = get(loadedMissing, lang);
    if (get(loadedMsgs, message)) {
      return message;
    } else {
      assign(loadedMsgs, { [message]: message });
      assign(loadedMissing, { [lang]: loadedMsgs });
    }

    axios.post("/lang/tr", { message, lang }).then((response) => {
      const msg = isUndefined(response.data.message)
        ? message
        : response.data.message;

      if (message !== msg && loadedLanguages.includes(lang)) {
        i18n.mergeLocaleMessage(lang, { [message]: msg });
      }

      return msg;
    });
  },
});

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  const html = document.querySelector("html");
  if (html) {
    html.setAttribute("lang", lang);
  }
  return lang;
}

export async function loadLanguageAsync(lang: string): Promise<void> {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }
  return await axios.get(`/lang/${lang}`).then((response) => {
    const msgs = response.data;
    loadedLanguages.push(lang);
    i18n.setLocaleMessage(lang, msgs.messages);
    setI18nLanguage(lang);
  });
}
