<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import type { ToastID } from "vue-toastification/dist/types/src/types";
import { POSITION } from "vue-toastification";
import IconLoading from "@/components/icons/IconLoading.vue";
import type {
  InvoiceData,
  InvoiceMovementTypeData,
} from "@planetadeleste/vue-mc-gw";
import { get, isNumber, isString } from "lodash";
import { EventBus } from "@/services/event-bus";
import { FlashModule } from "@/store/flash";
import useInvoice from "@/composables/invoice";
import { number } from "mathjs";
import type { InvoiceMovementTypeCode } from "@/types/utils";

@Component
export default class PrintInvoice extends Vue {
  loading = false;
  toastId: ToastID | null = null;
  obModel: Partial<InvoiceData> | null | undefined = null;

  get label() {
    return this.obModel
      ? `${this.obModel.order_serial}${this.obModel.order_number}`
      : "";
  }

  get uuid(): string | null {
    return this.obModel ? get(this.obModel, "uuid", null) : null;
  }

  get signed(): boolean {
    return !!this.obModel && get(this.obModel, "is_signed", false);
  }

  get iMovementType(): InvoiceMovementTypeCode | undefined {
    const obMovementType = get(this.obModel, "invoice_movement_type") as
      | InvoiceMovementTypeData
      | undefined;
    return obMovementType ? number(obMovementType.code) : undefined;
  }

  get canPrint() {
    return this.signed && (!!this.obModel?.id || !!this.uuid);
  }

  message() {
    this.toastId = this.$toast.info(
      this.$t("printing.invoice", { label: this.label }),
      {
        timeout: false,
        position: POSITION.BOTTOM_LEFT,
        icon: IconLoading,
      }
    );
  }

  closeMessage() {
    this.loading = false;

    if (!isNumber(this.toastId)) {
      return;
    }

    this.$toast.dismiss(this.toastId);
    this.toastId = null;
  }

  async onPrint() {
    try {
      if (!this.obModel && !this.uuid) {
        return;
      }

      const { print } = useInvoice();
      this.loading = true;
      this.message();
      await print(this.uuid ?? this.obModel?.id, () => {
        this.closeMessage();
      });
    } catch (e: any) {
      console.error(e);

      if (e.response) {
        let obResponse = await e.response.response.data.text();
        if (isString(obResponse)) {
          obResponse = JSON.parse(obResponse);
          if (obResponse.message) {
            FlashModule.error(obResponse.message);
          }
        }
      }

      this.closeMessage();
    }
  }

  mounted() {
    EventBus.on("invoice.print", (obData) => {
      if (isNumber(this.toastId)) {
        this.closeMessage();
      }

      this.obModel = obData;

      if (this.loading || !this.canPrint) {
        return;
      }

      this.onPrint();
    });
  }

  render() {
    return "";
  }
}
</script>
