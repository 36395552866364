export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicegroups",
    name: "invoicegroups.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/invoicegroups",
    name: "invoicegroups.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicegroups/list",
    name: "invoicegroups.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/invoicegroups/{id}",
    name: "invoicegroups.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/invoicegroups/{id}",
    name: "invoicegroups.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/invoicegroups/{id}",
    name: "invoicegroups.destroy",
  },
];
