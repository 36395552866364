import { forEach } from "lodash";

export interface PermissionRole {
  role: string;
  access: boolean;
  redirect?: string;
}
export type Role = "admin" | "company" | "customer" | "limited" | "guest";

const arRoles: Role[] = ["admin", "company", "customer", "limited", "guest"];

export const setRole = (sValue: Role) => {
  const arPermissions: PermissionRole[] = [];
  forEach(arRoles, (sRole) => {
    const obRole = {
      role: sRole,
      access: sValue === sRole || sRole === "admin",
      redirect: "error.404",
    };

    if (["limited", "customer"].includes(sValue) && sRole === "company") {
      obRole.access = true;
    }

    arPermissions.push(obRole);
  });

  return arPermissions;
};

export const admin = setRole("admin");

export const company = setRole("company");

export const customers = setRole("customer");

export const limited = setRole("limited");
