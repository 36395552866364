import store from "@/store";
import {
  CfeCode,
  type CfeCodeData,
  CurrencyRate,
  type CurrencyRateData,
  CurrencyRates,
  InvoiceType,
  type InvoiceTypeData,
  Module as ModuleModel,
  type ModuleData,
  Settings,
  type SettingsData,
} from "@planetadeleste/vue-mc-gw";
import { get, isNil, reduce, sortBy } from "lodash";
import dayjs from "dayjs";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { AppModule } from "./app";
import { Currency } from "@planetadeleste/vue-mc-shopaholic";
import useCompanyHelper from "@/composables/company";
import type { CurrencyWithPivotData } from "@/types/utils";

export interface MaxInvoicesUI {
  date: string;
  value: number;
}

const getClosestMaxInvoiceUI = (arValue: MaxInvoicesUI[]) => {
  const now = dayjs();
  return reduce(arValue, (obResult: MaxInvoicesUI, obItem: MaxInvoicesUI) => {
    if (isNil(arValue)) {
      return obItem;
    }

    return now.isSameOrAfter(obItem.date) ? obItem : obResult;
  });
};

@Module({
  dynamic: true,
  store,
  name: "config",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") !== null,
})
export default class ConfigStore extends VuexModule {
  private _company_currency_rates: CurrencyRateData[] = [];
  private _company_currencies: CurrencyWithPivotData[] = [];
  private _invoice_types: InvoiceTypeData[] = [];
  private _company_cfecodes: CfeCodeData[] = [];
  private _company_modules: ModuleData[] = [];
  private _settings: Partial<SettingsData> = {};

  get settings() {
    return new Settings(this._settings);
  }

  get maxInvoiceUI() {
    const obItem = getClosestMaxInvoiceUI(this._settings?.invoice_ui ?? []);
    return obItem ? obItem.value : null;
  }

  get maxUIAmount() {
    if (!this.maxInvoiceUI || !this._settings.currency_ui) {
      return 0;
    }

    return this.maxInvoiceUI * this._settings.currency_ui;
  }

  get companyCurrencyRates() {
    return new CurrencyRates(
      this._company_currency_rates.map((item) => new CurrencyRate(item))
    );
  }

  get companyCurrencyIdList(): number[] {
    return this._company_currencies.map((item) => item.id);
  }

  get companyCurrencies() {
    return this._company_currencies.map((item) => new Currency(item));
  }

  get companyInvoiceTypes(): InvoiceType[] {
    return this._invoice_types.map((item) => new InvoiceType(item));
  }

  get companyCfeCodes(): CfeCode[] {
    return this._company_cfecodes.map((item) => new CfeCode(item));
  }

  get companyModules(): ModuleModel[] {
    return this._company_modules.map((item) => new ModuleModel(item));
  }

  get companyModuleCodes(): string[] {
    return this._company_modules.map((item) => item.code);
  }

  get showCompanyBalance(): boolean {
    return this.settings.get("show_company_balance", false);
  }

  @Action
  async load() {
    const obModel = new Settings();
    await obModel.fetch();
    this.setSettings(obModel.attributes as SettingsData);
  }

  @Action
  async loadCompanyCurrencyRates(bForce = false) {
    if (!AppModule.company?.id) {
      return;
    }

    if (this._company_currency_rates.length && !bForce) {
      return;
    }

    const obCollection = new CurrencyRates();
    await obCollection
      .filterBy({ companyGrouped: AppModule.company.id })
      .fetch();

    this.setCompanyCurrencyRates(
      obCollection.getModelList() as CurrencyRateData[]
    );
  }

  @Action
  async loadCompanyCurrencies(bForce = false): Promise<void> {
    if (
      !AppModule.company?.id ||
      (this._company_currencies.length && !bForce)
    ) {
      return;
    }

    const obCompanyHelper = useCompanyHelper(AppModule.company);
    const obResponse = await obCompanyHelper.loadCurrencies();
    this.setCompanyCurrencies(obResponse ?? []);
  }

  @Action
  async loadCompanyInvoiceTypes(bForce = false): Promise<void> {
    if (!AppModule.company?.id || (this._invoice_types.length && !bForce))
      return;

    const obResponse = await AppModule.company.getInvoiceTypes();
    this.setInvoiceTypes(
      sortBy(obResponse.getData().data, (obItem: InvoiceTypeData) =>
        get(obItem, "pivot.sort_order", obItem.sort_order)
      )
    );
  }

  @Action
  async loadCompanyCfeCodes(bForce = false): Promise<void> {
    if (
      !AppModule.company ||
      !AppModule.company.id ||
      (this._company_cfecodes.length && !bForce)
    ) {
      return;
    }

    const obResponse = await AppModule.company.getCfeCodes();
    this.setCompanyCfeCodes(obResponse.getData().data);
  }

  @Action
  async loadCompanyModules(bForce = false): Promise<void> {
    if (
      !AppModule.company ||
      !AppModule.company.id ||
      (this._company_modules.length && !bForce)
    ) {
      return;
    }

    const obResponse = await AppModule.company.getModules();
    this.setCompanyModules(obResponse.getData().data);
  }

  @Mutation
  setCompanyCurrencyRates(value: CurrencyRateData[]) {
    this._company_currency_rates = value;
  }

  @Mutation
  setCompanyCurrencies(value: CurrencyWithPivotData[]) {
    this._company_currencies = value;
  }

  @Mutation
  setInvoiceTypes(value: InvoiceTypeData[]) {
    this._invoice_types = value;
  }

  @Mutation
  setCompanyCfeCodes(value: CfeCodeData[]) {
    this._company_cfecodes = value;
  }

  @Mutation
  setCompanyModules(value: ModuleData[]) {
    this._company_modules = value;
  }

  @Mutation
  setSettings(value: Partial<SettingsData>) {
    this._settings = value;
  }

  @Mutation
  reset(): void {
    this._company_currencies = [];
    this._company_currency_rates = [];
    this._invoice_types = [];
    this._company_cfecodes = [];
    this._company_modules = [];
  }
}

export const ConfigModule = getModule(ConfigStore);
