export default [
  // - CURRENCY RATES
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/currencyrates",
    name: "currencyrates.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/gw/currencyrates",
    name: "currencyrates.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/currencyrates/list",
    name: "currencyrates.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/currencyrates/{id}",
    name: "currencyrates.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/currencyrates/{id}",
    name: "currencyrates.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/currencyrates/{id}",
    name: "currencyrates.destroy",
  },
];
