import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import VuetifyConfirm from "vuetify-confirm";
import { i18n } from "@/plugins/i18n";

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: i18n.t("si"),
  buttonFalseText: i18n.t("no"),
});
