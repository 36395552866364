import { EventBus } from "@/services/event-bus";
import type { RoundByPosition } from "@/plugins/helpers";
import {
  cleanNumber,
  percent,
  positive,
  reverseTax,
  round,
  roundBy,
} from "@/plugins/helpers";
import store from "@/store";
import type {
  CompanyInvoiceTypePivot,
  DiscountData,
  FirmData,
  InvoiceData,
  InvoiceMovementTypeData,
  InvoicePaymentMethodData,
  InvoicePositionData,
  InvoiceReferenceData,
  InvoiceTypeData,
} from "@planetadeleste/vue-mc-gw";
import {
  Discount,
  Invoice,
  InvoiceMovementType,
  InvoicePosition,
  InvoiceReference,
  InvoiceType,
  TaxTypeCollection,
} from "@planetadeleste/vue-mc-gw";
import {
  assign,
  filter,
  forEach,
  get,
  has,
  isArray,
  isEmpty,
  map,
  omit,
  pick,
  set,
  values,
} from "lodash";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { AppModule } from "./app";
import { ConfigModule } from "./config";
import type { TaxTypeDataWithAmount } from "@/modules/invoices/mixins/InvoiceMixin";
import { number } from "mathjs";
import useMath from "@/composables/math";
import type {
  InvoiceMovementTypeCode,
  InvoiceTotals,
  InvoiceTotalsDiscount,
  InvoiceTotalsTax,
  InvoiceTypeCode,
} from "@/types/utils";
import type { RouteNamedMap } from "@/types/typed-router";

const { math, sumBy } = useMath();

@Module({ dynamic: true, store, name: "invoice", namespaced: true })
export default class InvoiceStore extends VuexModule {
  private _model: Invoice = new Invoice();
  private _taxTypes: TaxTypeCollection = new TaxTypeCollection();
  private _movementTypeCode: InvoiceMovementTypeCode[] = [];
  private _typeCode: InvoiceTypeCode | undefined = undefined;

  private _signing: number[] = [];

  get signing() {
    return this._signing;
  }

  private _arTaxes: TaxTypeDataWithAmount[] = [];

  get arTaxes(): TaxTypeDataWithAmount[] {
    if (this.inView && this.totals && this.totals.taxes?.length) {
      return map(this._arTaxes, (obTax, index) => {
        const obTotalTax = this.totals?.taxes[index];

        if (obTotalTax) {
          obTax.amount = obTotalTax.tax_value;
          obTax.total = obTotalTax.amount;
        }

        return obTax;
      }) as TaxTypeDataWithAmount[];
    }

    return this._arTaxes;
  }

  /**
   * @description Return Invoice model
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @readonly
   * @memberof InvoiceStore
   * @returns Invoice
   */
  get invoice() {
    return this._model;
  }

  get taxTypeCollection() {
    return this._taxTypes;
  }

  get routedMovementTypeCode(): InvoiceMovementTypeCode[] {
    return this._movementTypeCode;
  }

  get routedTypeCode() {
    return this._typeCode;
  }

  get fTaxesResult() {
    if (this.inView && this.totals) {
      return this.totals.total_taxes;
    }

    return sumBy<TaxTypeDataWithAmount>(this.arTaxes, "amount");
  }

  get currency() {
    if (!this.invoice.currency_id) {
      return AppModule.defaultCurrency;
    }

    return AppModule.currencies.find({ id: this.invoice.currency_id });
  }

  get currencyRate() {
    if (!this.currency || !ConfigModule.companyCurrencyRates.length) {
      return;
    }

    return ConfigModule.companyCurrencyRates.find({
      currency_id: this.currency.id,
    });
  }

  get positions(): InvoicePosition[] {
    return this.invoice.get("positions", []) as InvoicePosition[];
  }

  get references(): InvoiceReference[] {
    const arReferences = this.invoice.get("references", []);
    if (isEmpty(arReferences) || !isArray(arReferences)) {
      return [];
    }

    return arReferences.map((obData: InvoiceReferenceData) => {
      return obData instanceof InvoiceReference
        ? obData
        : new InvoiceReference(obData);
    });
  }

  /**
   * Global discounts
   */
  get discounts(): DiscountData[] {
    return map(this.invoice.get("discounts", []), (obItem: DiscountData) => {
      set(obItem, "value", number(obItem.value));
      return obItem;
    });
  }

  get invoiceMovementType(): Partial<InvoiceMovementTypeData> {
    return this.invoice.get("invoice_movement_type", {});
  }

  get invoicePaymentMethods(): InvoicePaymentMethodData[] {
    return this.invoice.get("payment_methods", []);
  }

  get invoiceType(): Partial<InvoiceTypeData> {
    return this.invoice.get("invoice_type");
  }

  get invoiceTypePivot(): Partial<CompanyInvoiceTypePivot> | undefined {
    return this.invoiceType ? this.invoiceType.pivot : undefined;
  }

  get priceWithTax(): boolean | undefined {
    return this.invoice.get("price_with_tax", false);
  }

  /**
   * @description Get total positions value with inline discounts and without global discounts
   */
  get fSubtotalWithoutDiscounts(): number {
    if (this.inView && this.totals) {
      return this.totals.subtotal;
    }

    if (this.signed) {
      return this.invoice.get("position_total_value", 0);
    }

    return sumBy(this.positions, (obPosition) => {
      if (!(obPosition instanceof InvoicePosition)) {
        obPosition = new InvoicePosition(obPosition);
      }

      const fValue = obPosition.get(
        "price_without_tax",
        obPosition.price
      ) as number;
      return math.multiply(fValue, obPosition.quantity);
    });
    // return round(fPrice);
  }

  get fDiscounts() {
    if (this.inView && this.totals) {
      return this.totals.total_discounts;
    }

    let fPrice = this.fSubtotalWithoutDiscounts;
    let fDiscountsValue = 0;

    if (this.discounts.length) {
      forEach(this.discounts, (obDiscount) => {
        let fValue = 0;
        if (!obDiscount.value) {
          return;
        }

        if (!obDiscount.fixed) {
          fValue = percent(fPrice, obDiscount.value);
          fPrice = math.subtract(fPrice, fValue);
        } else {
          const obDiscountModel =
            obDiscount instanceof Discount
              ? obDiscount
              : new Discount(obDiscount);

          // Iterate on positions to calculate discount value
          forEach(this.positions, (obPosition) => {
            if (!(obPosition instanceof InvoicePosition)) {
              obPosition = new InvoicePosition(obPosition);
            }

            const sField =
              !obDiscount ||
              obDiscount.priority === 1 ||
              !obPosition.price_with_discounts
                ? "price_without_tax"
                : "price_with_discounts";
            const fPositionPrice = number(
              obPosition.get(sField, obPosition.price)
            );

            if (!fPositionPrice) {
              return;
            }

            if (this.priceWithTax && obDiscount) {
              const fTax = number(obPosition.get("offer.tax_percent_value", 0));
              if (fTax) {
                const fValue = math.subtract(
                  obDiscount.value,
                  reverseTax(obDiscount.value, fTax)
                );
                // const fValue = round(
                //   math.subtract(
                //     obDiscount.value,
                //     reverseTax(obDiscount.value, fTax)
                //   )
                // );
                obDiscountModel.set("value", fValue);
              }
            }

            fValue = obDiscount ? obDiscountModel.calculate(fPositionPrice) : 0;
            fPrice = math.add(fPrice, fValue);
          });
        }

        fDiscountsValue = math.add(fDiscountsValue, positive(fValue));
      });
    }

    return fDiscountsValue;
    // return round(fDiscountsValue);
  }

  /**
   * Get total positions with inline and global discounts
   * @returns {Number}
   */
  get fSubtotal(): number {
    if (this.inView && this.totals) {
      return this.totals.subtotal;
    }

    if (this.signed) {
      return this.invoice.get("position_total_price_value", 0);
    }

    if (
      !this.positions.length &&
      this.iMovementType === InvoiceMovementType.CODE_DEBT_RYA
    ) {
      return number(this.invoice.get("price_limit", 0));
    }

    return sumBy(this.positions, (obPosition) => {
      if (!(obPosition instanceof InvoicePosition)) {
        obPosition = new InvoicePosition(obPosition);
      }

      const fValue = [
        InvoiceMovementType.CODE_RECEIPT ||
          InvoiceMovementType.CODE_RECEIPT_CORRECT,
      ].includes(this.iMovementType)
        ? obPosition.get("price_without_tax", obPosition.price)
        : obPosition.price_with_discounts ||
          obPosition.get("price_without_tax", obPosition.price);
      return math.multiply(fValue as number, obPosition.quantity);
    });
    // return round(fPrice);
  }

  /**
   * Get subtotal with global discounts
   * @returns {Number}
   */
  get fSubTotalWithDiscounts(): number {
    return math.subtract(this.fSubtotal, this.fDiscounts);
  }

  /**
   * Get total value with tax
   *
   * @date 21/10/2022 - 08:39:14
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fTotal() {
    if (this.signed) {
      return this._model.get("total_price_value", 0);
    }

    return math.add(this.fSubtotal, this.fTaxesResult);
  }

  /**
   * Get rounded total value
   *
   * @date 21/10/2022 - 08:34:17
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fTotalRounded() {
    if (this.inView && this.totals) {
      return this.totals.total;
    }

    return this.signed
      ? this._model.get("total_price_value", 0)
      : roundBy(this.fTotal, this.fRoundBy);
  }

  /**
   * Get total paid value (sum of payment methods)
   *
   * @readonly
   * @type {number}
   */
  get fPaid() {
    const fValue = this.invoicePaymentMethods.length
      ? sumBy<InvoicePaymentMethodData>(
          this.invoicePaymentMethods,
          (obData) => {
            return number(obData.amount);
          }
        )
      : 0;

    return positive(math.subtract(this.fTotal, fValue)) > 0.01
      ? fValue
      : this.fTotalRounded;
  }

  /**
   * Get rounding value
   *
   * @date 21/10/2022 - 08:35:40
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fRoundValue() {
    if (this.inView && this.totals) {
      return this.totals.rounded_value;
    }

    return this.signed
      ? this._model.get("rounded_price_value", 0)
      : cleanNumber(math.subtract(this.fTotalRounded, this.fTotal));
  }

  /**
   * Get currency setting of round by value
   *
   * @date 21/10/2022 - 08:30:47
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fRoundBy(): RoundByPosition {
    let fRound = this._model.get("round", 1);

    if (fRound === "1.00" || fRound === "1.0" || !fRound) {
      fRound = 1;
    } else if (fRound === "0.00") {
      fRound = 0;
    }

    if (
      this.currencyRate &&
      // this.currencyRate.round &&
      this.iMovementType === InvoiceMovementType.CODE_SALES
    ) {
      fRound = this.currencyRate.round as RoundByPosition;
    }

    return fRound;
  }

  get maxUIAmount(): number {
    return ConfigModule.maxUIAmount;
  }

  get subtotalExceedMaxUI() {
    return (
      this.fSubtotal > 0 &&
      this.maxUIAmount > 0 &&
      this.fSubtotal > this.maxUIAmount
    );
  }

  get customerFirm(): Partial<FirmData> {
    return this._model.get("customer_firm", {});
  }

  get sTypeCode(): number {
    return this.invoiceType ? number(get(this.invoiceType, "code", 0)) : 0;
  }

  /**
   * Get true if invoice can be emitted
   * @date 2/8/2022 - 08:26:49
   * @author Planeta del Este
   *
   * @readonly
   * @type {boolean}
   */
  get canSign() {
    // Sub total must have a value
    if (this.invoice.is_signed || (!this.fSubtotal && !this.positions.length)) {
      return false;
    }

    // Check for subtotal < max UI value and invoice type is not e-resguardo
    if (
      !this.subtotalExceedMaxUI &&
      this.sTypeCode !== InvoiceType.CODE_ERESGUARDO
    ) {
      return true;
    }

    if (!this.customerFirm) {
      return false;
    }

    const arKeys = [
      "doc_id",
      "dgi_denominacion",
      "dgi_dir_fiscal",
      "dgi_loc_nom",
      "dgi_dpto_nom",
    ];
    const obFirmData = pick(this.customerFirm, arKeys);
    const arFirmValues = values(obFirmData);
    const arFirmValid = filter(arFirmValues, (sVal) => !isEmpty(sVal));

    return arFirmValid.length === arKeys.length;
  }

  get isSigning(): boolean {
    return (
      !!this._model.id &&
      !!this._signing.length &&
      this._signing.includes(this._model.id)
    );
  }

  get signed(): boolean {
    return this.invoice.is_signed || this.inView;
  }

  get inView(): boolean {
    return this.invoice.get("in_view", false);
  }

  get iMovementType(): InvoiceMovementTypeCode {
    const sKey =
      this.signed || this._model.id
        ? "invoice_movement_type.code"
        : "invoice_movement_type_code";

    return number(this._model.get(sKey, 0));
  }

  get totals(): InvoiceTotals | null {
    const obTotals = this._model.get("totals", null);
    return obTotals && has(obTotals, "total") && has(obTotals, "subtotal")
      ? obTotals
      : null;
  }

  get isDebt() {
    return [
      InvoiceMovementType.CODE_DEBT,
      InvoiceMovementType.CODE_DEBT_RYA,
    ].includes(this.iMovementType);
  }

  get isReceived(): boolean {
    return !!this.invoice.get("received_at");
    // return [
    //   InvoiceMovementType.CODE_BUY,
    //   InvoiceMovementType.CODE_PAY,
    // ].includes(this.iMovementType);
  }

  @Action
  setInvoice(obData: Invoice | InvoiceData | Record<string, any>) {
    const obInvoiceData: Partial<InvoiceData> =
      obData instanceof Invoice ? obData.attributes : obData;
    const arPositions: InvoicePositionData[] = obInvoiceData.positions || [];

    if (
      obInvoiceData.is_signed &&
      obInvoiceData.customer_firm &&
      obInvoiceData.customer?.firm
    ) {
      const obFirmData = assign(
        {},
        obInvoiceData.customer.firm,
        obInvoiceData.customer_firm
      );
      set(obInvoiceData, "customer.firm", obFirmData);
    }

    this._setInvoice(
      arPositions.length ? omit(obInvoiceData, ["positions"]) : obInvoiceData
    );
    this.setPositions(arPositions, true);
  }

  @Action
  async loadTaxTypes(bForce = false) {
    if (this._taxTypes.length && !bForce) {
      return;
    }

    await this._taxTypes.fetch();
  }

  /**
   * Add invoice ID to signing flag
   *
   * @date 11/11/2022 - 09:25:21
   * @author Planeta del Este
   *
   * @param {number} sValue
   */
  @Action
  addSign(sValue: number) {
    if (this._signing.includes(sValue)) {
      return;
    }

    this._signing.push(sValue);
    EventBus.emit("invoice.signing", sValue);
  }

  /**
   * Remove invoice ID from signing flag
   * @date 11/11/2022 - 09:25:46
   * @author Planeta del Este
   *
   * @param {number} sValue
   * @param unsigned
   */
  @Action
  removeSign(sValue: number, unsigned?: boolean) {
    if (!this._signing.includes(sValue)) {
      return;
    }

    const idx = this._signing.indexOf(sValue);
    if (idx !== -1) {
      this._signing.splice(idx, 1);
      const sChannel = unsigned ? "unsigned" : "signed";
      EventBus.emit(`invoice.${sChannel}`, sValue);
    }
  }

  @Action
  setTaxesAmount(arTaxes: TaxTypeDataWithAmount[]) {
    this._setTaxes(arTaxes);
  }

  @Action
  setPositions(
    arPositions: InvoicePositionData[] | InvoicePosition[],
    force: boolean = false
  ) {
    const arPositionModelList: InvoicePosition[] = [];

    if (this.signed && this.positions.length && !force) {
      return;
    }

    forEach(arPositions, (obData) => {
      const obModel =
        obData instanceof InvoicePosition
          ? obData
          : new InvoicePosition(obData);

      // Set vars with/without tax based on position price with applied inline discounts
      const obTaxType = obModel.tax_type_id
        ? this.taxTypeCollection.find({ id: obModel.tax_type_id })
        : undefined;
      const fPrice =
        this.signed || !!obModel.id
          ? obModel.get("price_without_tax", obModel.price)
          : obModel.price;

      let fPriceWithoutTax = fPrice;
      let fPriceWithTax = fPrice;
      let fUnitPriceWithoutTax = obModel.unit_price;
      let bCalculate = true;

      if (
        this.signed ||
        !!obModel.id ||
        this.iMovementType === InvoiceMovementType.CODE_RECEIPT_CORRECT ||
        this.iMovementType === InvoiceMovementType.CODE_RECEIPT ||
        this.iMovementType === InvoiceMovementType.CODE_DEBT ||
        this.iMovementType === 14
      ) {
        fPriceWithTax = obModel.price;

        if (!obModel.id) {
          fUnitPriceWithoutTax = fPrice;
          bCalculate = false;
        }
      }

      if (fPrice && obTaxType && obTaxType.percent && bCalculate) {
        if (!obModel.id) {
          fPriceWithoutTax = this.priceWithTax
            ? math.subtract(fPrice, reverseTax(fPrice, obTaxType.percent))
            : fPrice;
          fPriceWithTax = this.priceWithTax
            ? fPrice
            : math.add(fPrice, percent(fPrice, obTaxType.percent));
        }

        fUnitPriceWithoutTax = this.priceWithTax
          ? math.subtract(
              obModel.unit_price,
              reverseTax(obModel.unit_price, obTaxType.percent)
            )
          : obModel.unit_price;
      }

      obModel.set("price_with_tax", fPriceWithTax);
      obModel.set("price_without_tax", fPriceWithoutTax);
      obModel.set("unit_price_without_tax", fUnitPriceWithoutTax);

      arPositionModelList.push(obModel);
    });

    this._model.set("positions", arPositionModelList);
  }

  @Action
  setTotals() {
    const obTotals: Partial<InvoiceTotals> = {
      subtotal: round(this.fSubtotalWithoutDiscounts),
      total_discounts: round(this.fDiscounts, 3),
      total_taxes: round(sumBy(this.arTaxes, "amount")),
      total: this.fTotalRounded,
      rounded_value: round(this.fRoundValue),
      taxes: [],
      discounts: [],
    };

    // Add discounts to totals
    if (this.discounts.length && this.fSubtotalWithoutDiscounts) {
      let fPrice = this.fSubtotalWithoutDiscounts;
      const arDiscountList: InvoiceTotalsDiscount[] = [];
      forEach(this.discounts, (obDiscount, idx) => {
        let fValue = 0;
        if (!obDiscount.value || obDiscount.fixed) {
          return;
        }

        fValue = percent(fPrice, obDiscount.value);
        fPrice = math.subtract(fPrice, fValue);

        arDiscountList.push({
          percent: number(obDiscount.value),
          amount: round(fValue, 3),
          sort_order: idx,
        });
      });

      obTotals.discounts = arDiscountList;
    }

    // Add taxes to totals
    if (this.arTaxes.length) {
      obTotals.taxes = map(this.arTaxes, (obTax, idx) => {
        return {
          tax_type_id: obTax.id,
          tax_value: obTax.amount ? round(obTax.amount) : 0,
          amount: obTax.total ? round(obTax.total) : 0,
          percent: number(obTax.percent),
          sort_order: idx,
        };
      }) as InvoiceTotalsTax[];
    }

    this._model.set("totals", obTotals);
  }

  @Action
  setRoutedMovementTypeCode(sRouteName: keyof RouteNamedMap) {
    let arMovementTypeCode: InvoiceMovementTypeCode[];
    let sTypeCode: InvoiceTypeCode | undefined = undefined;

    switch (true) {
      case /^(invoices\.received\.)(.*)$/i.test(sRouteName):
        arMovementTypeCode = [
          InvoiceMovementType.CODE_BUY,
          InvoiceMovementType.CODE_PAY,
        ];
        break;

      case /^(invoices\.debt\.)(.*)$/i.test(sRouteName):
        arMovementTypeCode = [
          InvoiceMovementType.CODE_DEBT,
          InvoiceMovementType.CODE_CANCEL_DEBT,
          15,
        ];
        break;

      case /^(invoices\.receipt\.)(.*)$/i.test(sRouteName):
        arMovementTypeCode = [InvoiceMovementType.CODE_PAY];
        sTypeCode = 701;
        break;

      case /^(invoices\.create)$/i.test(sRouteName):
      case /^(invoices\.)(emitted|saved)$/i.test(sRouteName):
      default:
        arMovementTypeCode = [
          InvoiceMovementType.CODE_SALES,
          InvoiceMovementType.CODE_REFOUND,
          InvoiceMovementType.CODE_DEBIT_NOTE,
          InvoiceMovementType.CODE_RECEIPT,
          InvoiceMovementType.CODE_RECEIPT_CORRECT,
          InvoiceMovementType.CODE_SALES_EXP,
        ];
        break;
    }

    if (arMovementTypeCode.length || this.routedMovementTypeCode.length) {
      this._setRoutedMovementTypeCode(arMovementTypeCode);
      this._setRoutedTypeCode(sTypeCode);
    }
  }

  @Mutation
  private _setInvoice(obData: Invoice | InvoiceData | Record<string, any>) {
    this._model =
      obData instanceof Invoice ? obData : new Invoice(obData as InvoiceData);
  }

  @Mutation
  private _setTaxes(arTaxes: TaxTypeDataWithAmount[]) {
    this._arTaxes = arTaxes;
  }

  @Mutation
  private _setRoutedMovementTypeCode(
    arMovementTypeCode: InvoiceMovementTypeCode[]
  ) {
    this._movementTypeCode = arMovementTypeCode;
  }

  @Mutation
  private _setRoutedTypeCode(sTypeCode?: InvoiceTypeCode) {
    this._typeCode = sTypeCode;
  }
}

export const InvoiceModule = getModule(InvoiceStore);
