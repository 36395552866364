<template>
  <div class="full-width text-right" v-if="path && itemId">
    <v-btn
      :to="`/${path}/view/${itemId}`"
      icon
      text
      color="primary"
      small
      v-if="!hideView"
    >
      <v-icon>mdi-eye</v-icon>
    </v-btn>
    <v-btn
      :to="`/${$_.trimStart(path, '/')}/update/${itemId}`"
      icon
      text
      color="primary"
      small
      v-if="!hideUpdate"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn
      @click.stop="deleteItem()"
      icon
      text
      color="red lighten-2"
      small
      v-if="!hideDelete"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>

    <slot />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ActionButtonsTable extends Vue {
  @Prop({ type: [String, Number], required: true }) readonly itemId:
    | string
    | number
    | undefined;
  @Prop({ type: String, required: true }) readonly path: string | undefined;
  @Prop({ type: Boolean, default: false }) hideView: boolean | undefined;
  @Prop({ type: Boolean, default: false }) hideUpdate: boolean | undefined;
  @Prop({ type: Boolean, default: false }) hideDelete: boolean | undefined;

  deleteItem() {
    this.$emit("delete", this.itemId);
  }
}
</script>
