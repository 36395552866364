export default {
  path: "offer",
  page: "list",
  name: "offer",
  children: [
    {
      path: "update/:offer_id",
      page: "list",
      name: "update",
    },
    {
      path: "create",
      page: "list",
      name: "create",
    },
    {
      path: "view/:offer_id",
      page: "list",
      name: "view",
    },
  ],
};
