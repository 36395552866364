import { GwCacheModule } from "@/store/cache";
import { get, isNumber, isObjectLike, isString } from "lodash";

export default class CacheService {
  cacheKey = "GW";
  cacheIdentifier = "id";

  constructor(sIdentifier?: string, sKey?: string) {
    if (sIdentifier) {
      this.cacheIdentifier = sIdentifier;
    }

    if (sKey) {
      this.cacheKey = sKey;
    }
  }
  cache<T extends Record<string, any>>(
    obData?: T | number | string,
    sCacheKey?: string
  ): T | undefined {
    if (!sCacheKey) {
      sCacheKey = this.cacheKey;
    }

    const iDataId = !obData
      ? this.cacheIdentifier
      : isNumber(obData) || isString(obData)
      ? obData
      : get(obData, this.cacheIdentifier, this.cacheIdentifier);

    const sKey = `${sCacheKey}.${iDataId}`;

    if (obData && isObjectLike(obData)) {
      GwCacheModule.set({ [sKey]: obData });
    }

    return get(this.cacheData, sKey);
  }

  get cacheData() {
    return GwCacheModule.cache;
  }
}
