import { company } from "@/router/permissions";
import { create } from "@/router/utils";

const obRoute = create(
  "accountingentryinvoices",
  null,
  "index",
  {
    sortOrder: 999,
    iconProps: { icon: "people-outline" },
  },
  company
);

export default [obRoute];
