<template>
  <v-sheet
    :class="cssClass"
    class="holder-sheet"
    v-bind="$attrs"
    @click="click"
  >
    <slot name="head" />

    <!-- Slot top-attached -->
    <slide-y-up-transition>
      <template v-if="hasTopAttached">
        <div class="holder-sheet--attached">
          <div class="content">
            <slot name="top-attached" />
          </div>
        </div>
      </template>
    </slide-y-up-transition>

    <!-- Slot default -->
    <div :class="{ content: !noWrapDefaultSlot }" class="relative">
      <slot />
    </div>

    <!-- Slot bottom-attached -->
    <slide-y-up-transition>
      <template v-if="hasBottomAttached">
        <div class="holder-sheet--attached">
          <div class="content">
            <slot name="bottom-attached" />
          </div>
        </div>
      </template>
    </slide-y-up-transition>
  </v-sheet>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { SlideYUpTransition } from "vue2-transitions";

/**
 * The `holder sheet` component use `v-sheet` as main component and
 * add slots and props
 */
@Component({
  components: { SlideYUpTransition },
})
export default class Sheet extends Vue {
  /**
   * Remove box shadow and set light grey background
   * @type {boolean}
   * @default false
   */
  @Prop(Boolean) readonly depressed!: boolean;

  /**
   * Reduce content padding to **1rem**
   * @type {boolean}
   * @default false
   */
  @Prop(Boolean) readonly dense!: boolean;

  /**
   * Remove padding on content element
   * @type {boolean}
   * @default false
   */
  @Prop(Boolean) readonly noPadding!: boolean;

  /**
   * Set sheet without background color
   * @type {boolean}
   * @default false
   */
  @Prop(Boolean) readonly transparent!: boolean;

  /**
   * Set sheet with border
   * @type {boolean}
   * @default false
   */
  @Prop(Boolean) readonly outlined!: boolean;

  /**
   * Set sheet with light background color
   * @type {boolean}
   * @default false
   */
  @Prop(Boolean) readonly light!: boolean;

  /**
   * Hide top attached slot
   * @type {boolean}
   * @default false
   */
  @Prop(Boolean) readonly hideTopAttached!: boolean;

  /**
   * Hide bottom attached slot
   * @type {boolean}
   * @default false
   */
  @Prop(Boolean) readonly hideBottomAttached!: boolean;

  /**
   * Prevent wrap default slot
   * @type {boolean}
   * @default false
   */
  @Prop(Boolean) readonly noWrapDefaultSlot!: boolean;

  get cssClass() {
    return {
      depressed: this.depressed,
      dense: this.dense,
      "no-padding": this.noPadding,
      transparent: this.transparent,
      outlined: this.outlined,
      light: this.light,
      hoverable: this.isClickable,
    };
  }

  get hasTopAttached() {
    return !!this.$_.get(this.$slots, "top-attached") && !this.hideTopAttached;
  }

  get hasBottomAttached() {
    return (
      !!this.$_.get(this.$slots, "bottom-attached") && !this.hideBottomAttached
    );
  }

  get hasDefaultSlot() {
    return !!this.$_.get(this.$slots, "default");
  }

  get isClickable(): boolean {
    return (
      !!this.$listeners.click ||
      !!this.$listeners["!click"] ||
      !!this.$attrs.tabindex
    );
  }

  click(e: MouseEvent | KeyboardEvent) {
    this.$emit("click", e);
  }
}
</script>
