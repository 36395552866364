import type { List } from "lodash";
import { forEach, get, isEmpty, isString } from "lodash";
import { all, create } from "mathjs";

const math = create(all, { number: "BigNumber" });
export default function useMath() {
  const sumBy = <T>(
    arCollection: List<T>,
    iteratee: ((value: T) => number) | string
  ) => {
    if (isEmpty(arCollection)) {
      return 0;
    }

    let fAmount = 0;

    forEach(arCollection, (obItem) => {
      let fValue = 0;

      if (isString(iteratee)) {
        fValue = get(obItem, iteratee, 0) as number;
      } else {
        fValue = iteratee(obItem);
      }

      fAmount = math.add(fAmount, fValue);
    });

    return fAmount;
  };

  return { math, sumBy };
}
