export default [
  // - Branches
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/branches",
    name: "branches.index",
  },
  { methods: ["POST"], uri: "api/v1/gw/branches", name: "branches.store" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/branches/list",
    name: "branches.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/branches/{id}",
    name: "branches.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/branches/{id}",
    name: "branches.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/branches/{id}",
    name: "branches.destroy",
  },
];
