<template>
  <v-card class="transparent pde-main-nav" flat>
    <v-divider />
    <v-list dense nav class="px-0">
      <nav-group :items="items" root>
        <template #item-invoices-saved="{ item: obItem }">
          <invoice-counter-menu-item :item="obItem" invoice-type="saved" />
        </template>
        <template #item-invoices-debt-saved="{ item: obItem }">
          <invoice-counter-menu-item :item="obItem" invoice-type="debt.saved" />
        </template>
        <template #item-invoices-received-unprocessed="{ item: obItem }">
          <invoice-counter-menu-item
            :item="obItem"
            invoice-type="received.unprocessed"
          />
        </template>
      </nav-group>
    </v-list>
  </v-card>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-var-requires */

import { Component, Vue, Watch } from "vue-property-decorator";
import { AuthModule } from "@/store/auth";
import NavGroup from "@/layout/components/main/drawer/NavGroup.vue";
import NavItem from "@/layout/components/main/drawer/NavItem.vue";
import type { MenuItem } from "@/config/mainMenu";
import mainMenu from "@/config/mainMenu";
import { delay, filter, map } from "lodash";
import { number } from "mathjs";
import type { InvoiceTypeCode } from "@/types/utils";
import { ConfigModule } from "@/store/config";
import { EventBus } from "@/services/event-bus";
import InvoiceCounterMenuItem from "@/modules/invoices/components/menu/InvoiceCounterMenuItem.vue";
import ReleaseMenuItem from "@/modules/releases/components/ReleaseMenuItem.vue";
import useMenu from "@/composables/menu";
import { canRouteAccess } from "@/services/moduleAccess";

@Component({
  components: { ReleaseMenuItem, InvoiceCounterMenuItem, NavGroup, NavItem },
})
export default class MainDrawer extends Vue {
  items: MenuItem[] = [];
  obMenu = useMenu(mainMenu, this.$router, this.validateItem);

  get arCompanyInvoiceTypes() {
    return ConfigModule.companyInvoiceTypes;
  }

  get arCompanyModuleCodes() {
    return ConfigModule.companyModuleCodes;
  }

  get arInvoiceTypeCodeList(): InvoiceTypeCode[] {
    return map(this.arCompanyInvoiceTypes, (obItem) => {
      return number(obItem.code);
    });
  }

  get user() {
    return AuthModule.user;
  }

  @Watch("arInvoiceTypeCodeList")
  onChangeInvoiceTypeCodeList() {
    this.onSetItemsDelayed();
  }

  @Watch("arCompanyModuleCodes")
  onChangeCompanyModuleCodes() {
    this.onSetItemsDelayed();
  }

  validateInvoiceType(obItem: MenuItem) {
    if (!obItem.children) {
      return true;
    }

    const arChilds = filter(obItem.children, (obChild: MenuItem) => {
      const arToParts = obChild.to?.split(".");
      if (
        !arToParts ||
        !arToParts.length ||
        arToParts.length < 2 ||
        arToParts[0] !== "invoices"
      ) {
        return true;
      }

      if (!this.arInvoiceTypeCodeList.length) {
        return false;
      }

      if (
        arToParts[1] === "receipt" &&
        !this.arInvoiceTypeCodeList.includes(701)
      ) {
        return false;
      }

      if (
        arToParts[1] === "debt" &&
        !this.arInvoiceTypeCodeList.includes(901)
      ) {
        return false;
      }

      return true;
    });

    return arChilds.length > 0;
  }

  validateReports(obItem: MenuItem) {
    if (!obItem.to) {
      return true;
    }

    const arToParts = obItem.to.split(".");
    if (arToParts.length < 2 || arToParts[0] !== "reports") {
      return true;
    }

    if (!this.arInvoiceTypeCodeList.length) {
      return false;
    }

    if (
      arToParts[1] === "receipt" &&
      !this.arInvoiceTypeCodeList.includes(182)
    ) {
      return false;
    }

    return true;
  }

  validateModules(obItem: MenuItem): boolean {
    if (!obItem.to && !obItem.children) {
      return true;
    }

    if (obItem.children) {
      return filter(obItem.children, this.validateModules).length > 0;
    }

    return obItem.to ? canRouteAccess(obItem.to) : true;
  }

  validateItem(obItem: MenuItem) {
    if (
      !this.validateInvoiceType(obItem) ||
      !this.validateReports(obItem) ||
      !this.validateModules(obItem)
    ) {
      return false;
    }

    return true;
  }

  onSetItems() {
    this.items = this.obMenu.setItems();
  }

  onSetItemsDelayed() {
    delay(this.onSetItems, 500);
  }

  created() {
    EventBus.on("app.company.selected", this.onSetItemsDelayed);
  }

  beforeDestroy() {
    EventBus.off("app.company.selected", this.onSetItemsDelayed);
  }

  mounted() {
    this.onSetItems();
  }
}
</script>
